import { parking } from 'api';
import notify from 'devextreme/ui/notify';

export const configTypeInTaxInvoicePopup = async (setTaxIssueType, setTaxInvoiceType, setTaxType) => {
  const taxIssueType = [];
  const taxType = [];
  const taxInvoiceType = [];
  const result = await parking.getParkingInfoSelectType({
    CodeGroup: ['TaxIssueType', 'TaxType', 'TaxInvoiceType'],
  });
  if (result.isOk && result.data.length > 0) {
    result.data.forEach(obj => {
      if (obj.CodeGroup === 'TaxIssueType') {
        taxIssueType.push({
          label: obj.CodeDesc,
          value: obj.Code,
          type: 'TaxIssueType',
        });
      } else if (obj.CodeGroup === 'TaxType') {
        taxType.push({
          label: obj.CodeDesc,
          value: obj.Code,
          type: 'TaxType',
        });
      } else if (obj.CodeGroup === 'TaxInvoiceType') {
        taxInvoiceType.push({
          label: obj.CodeDesc,
          value: obj.Code,
          type: 'TaxInvoiceType',
        });
      }
    });
    setTaxIssueType(taxIssueType);
    setTaxInvoiceType(taxInvoiceType);
    setTaxType(taxType);
  }
};

export const configBySelectedCustomerInfo = (selectedCustomInfo, setAddParams, addParams, SalesDate) => {
  if (selectedCustomInfo.length > 0) {
    setAddParams({
      ...addParams,
      CustomerCorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
      CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
      NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
      CustomerManagerGroup: selectedCustomInfo[0].CustomerManagerGroup,
      CustomerManagerName: selectedCustomInfo[0].CustomerManagerName,
      CustomerEmail1: selectedCustomInfo[0].Email,
      CustomerTelephoneNo: selectedCustomInfo[0].CustomerTelephoneNo,
      CustomerCode: selectedCustomInfo[0].CustomerNo,
      SalesDate,
    });
  }
};

export const makeTaxInvoiceStatusCellRender = e => {
  const { TaxInvoiceStatusName, SapStatus } = e.data;
  let color;

  if (SapStatus === 'BC') {
    color = 'green';
  } else if (SapStatus === 'W') {
    color = 'gray';
  } else if (SapStatus === 'NC') {
    color = 'red';
  } else if (SapStatus === 'Y') {
    color = 'blue';
  } else if (SapStatus === 'AC') {
    color = 'blue';
  } else if (SapStatus === 'N') {
    color = 'red';
  } else if (SapStatus === 'C') {
    color = 'purple';
  }
  return (
    <div style={{ width: 'auto' }} className={`Badge badge-status-${color}`}>
      {TaxInvoiceStatusName}
    </div>
  );
};
export const makePaysysStatusCellRender = e => {
  const { PayRequestStatusName } = e.data;
  let color;
  if (PayRequestStatusName) {
    // //   // 1. 통합결제시스템용 별도 생성
    // if (PayRequestStatusName === '요청완료') {
    //   color = 'grey';
    // } else if (PayRequestStatusName === '결제완료') {
    //   color = 'blue';
    // } else if (PayRequestStatusName === '요청만료') {
    //   color = 'red';
    // } else if (PayRequestStatusName === '요청실패') {
    //   color = 'red';
    // } else if (PayRequestStatusName === '요청취소') {
    //   color = 'purple';
    // }
    // return (
    //   <div style={{ width: 'auto', fontWeight: 400, color: 'white' }} className={`Badge badge-status-${color}`}>
    //     {PayRequestStatusName}
    //   </div>
    // );
    // 2. 세금계산서(현금영수증) 범주 따라간 케이스
    if (PayRequestStatusName === '요청완료') {
      // color = 'grey';
    } else if (PayRequestStatusName === '결제완료') {
      color = 'blue';
    } else if (PayRequestStatusName === '요청만료') {
      color = 'grey';
    } else if (PayRequestStatusName === '요청실패') {
      color = 'red';
    } else if (PayRequestStatusName === '요청취소') {
      color = 'purple';
    }
    return (
      <div style={{ width: 'auto', color: 'black' }} className={`Badge badge-status-${color}`}>
        {PayRequestStatusName}
      </div>
    );
    // 3.대사중 대사완료 범주 따라간 케이스
    // if (PayRequestStatusName === '요청완료') {
    //   color = 'blue';
    // } else if (PayRequestStatusName === '결제완료') {
    //   color = 'green';
    // } else if (PayRequestStatusName === '요청만료') {
    //   color = 'red';
    // } else if (PayRequestStatusName === '요청실패') {
    //   color = 'red';
    // } else if (PayRequestStatusName === '요청취소') {
    //   color = 'red';
    // }
    // return (
    //   <div style={{ width: 'auto', color: 'black' }} className={`Badge badge-status-${color}`}>
    //     {PayRequestStatusName}
    //   </div>
    // );
    // 4. UI초안 작성 범주
    // if (PayRequestStatusName === '요청완료') {
    //   // color = 'blue';
    // } else if (PayRequestStatusName === '결제완료') {
    //   color = 'blue';
    // } else if (PayRequestStatusName === '요청만료') {
    //   color = 'red';
    // } else if (PayRequestStatusName === '요청실패') {
    //   color = 'red';
    // } else if (PayRequestStatusName === '요청취소') {
    //   color = 'purple';
    // }
    // return (
    //   <div style={{ width: 'auto', color: 'black' }} className={`Badge badge-status-${color}`}>
    //     {PayRequestStatusName}
    //   </div>
    // );
  }
};
export const makePaysysStatus = e => {
  let color;
  if (e !== undefined) {
    const data = e;
    if (data === '요청완료') {
      // color = 'lightblue';
    } else if (data === '결제완료') {
      color = 'blue';
    } else if (data === '요청만료') {
      color = 'grey';
    } else if (data === '요청실패') {
      color = 'red';
    } else if (data === '요청취소') {
      color = 'purple';
    }
    return (
      <div style={{ width: 'auto', color: 'black' }} className={`Badge badge-status-${color}`}>
        {data}
      </div>
    );
  }
};
export const notifyDelayS = (message, messageType = 'error') => {
  notify(
    {
      message: message,
      width: 230,
      position: {
        at: 'top',
        my: 'top',
        of: '#container',
      },
    },
    messageType,
    100000,
  );
};

export const notifyS = (message, messageType = 'error') => {
  notify(
    {
      message: message,
      width: 230,
      position: {
        at: 'top',
        my: 'top',
        of: '#container',
      },
    },
    messageType,
  );
};
