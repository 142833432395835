import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { Paging, Column, Selection, TotalItem, Summary, Scrolling } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { Button } from 'devextreme-react/button';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { autoDataSource } from '../api/costCenterData';
import { master, salesPayment, error } from 'api';
import { etcSalesMngManualLink } from 'constants/hyperlink';
import { CheckBox } from 'devextreme-react';
import DateBox from 'devextreme-react/date-box';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import CommonDataGrid from 'common/default-data-grid';
import AddSalesMaster from './component/addSalesMaster';
import PaymentForm from 'common/popup/payment-form';
import PaymentDetailForm from 'common/popup/payment-detail-form';
import './etc-sales-master.scss';

const EtcSalesMaster = () => {
  const gridRef = useRef({});
  const addSaleMasterRef = useRef({});
  const paymentRef = useRef({});
  const paymentDetailRef = useRef({});
  const [autoText, setAutoText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [paymentPopup, setPaymentPopup] = useState({
    save: false,
    visible: false,
  });
  const [paymentDetailPopup, setPaymentDetailPopup] = useState({
    save: false,
    visible: false,
  });
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchParams, setSearchParams] = useState({
    CostCenterCode: '',
    CostCenterName: '',
    EtcSalesType: '',
    SearchText: '',
    FromDate: dateValue.FromDate,
    ToDate: dateValue.ToDate,
  });

  const [etcType, setEtcType] = useState([]);
  const [customerType, setCustomerType] = useState([]);
  const [paymentType, setPaymentType] = useState([]);

  const [selectedRowdata, setSelectedRowdata] = useState([]);
  const [selectedSummary, setSelectedSummary] = useState({ count: 0, total: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    etcSalesType();
  }, []);

  const etcSalesType = async () => {
    await master.searchCodeMaster({ CodeGroup: ['EtcSalesType', 'CustomerType', 'PaymentType'] }).then(res => {
      if (res.isOk) {
        const etcType = [];
        const customerType = [];
        const paymentType = [];

        res.data?.forEach(t => {
          if (t.CodeGroup === 'EtcSalesType') {
            etcType.push({
              id: t.CodeDesc,
              value: t.Code,
            });
          } else if (t.CodeGroup === 'CustomerType') {
            customerType.push({
              id: t.CodeDesc,
              value: t.Code,
            });
          } else if (t.CodeGroup === 'PaymentType') {
            paymentType.push({
              id: t.CodeDesc,
              value: t.Code,
            });
          }
        });
        setEtcType(etcType);
        setCustomerType(customerType);
        setPaymentType(paymentType);
      }
    });
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'TicketID',
      loadMode: 'raw',
      load: async () => {
        const { CostCenterCode, EtcSalesType, SearchText, FromDate, ToDate } = searchParams;
        const result = await salesPayment.getEtcSalesHistory({
          CostCenterCode,
          EtcSalesType,
          SearchText,
          FromDate,
          ToDate,
        });
        return result.data;
      },
    });

    return customDataSource;
  }, [searchParams]);

  const onValueChanged = e => {
    setAutoText(e.value);
  };
  const onItemClick = e => {
    const { CostCenterCode, CostCenterName } = e.itemData;
    setSearchParams({
      ...searchParams,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
      CostCenterCode,
      CostCenterName,
    });
  };

  const onChangeCustomer = e => {
    setSearchText(e.value);
  };

  const onEnterKey = e => {
    setSearchParams({
      ...searchParams,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
      CostCenterCode: autoText === null ? '' : searchParams.CostCenterCode,
      CostCenterName: autoText === null ? '' : searchParams.CostCenterName,
      SearchText: searchText.trim(),
    });
  };

  const onClickEtcTypes = e => {
    const { value } = e.itemData;
    setSearchParams({
      ...searchParams,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
      EtcSalesType: value,
    });
  };

  const customizeText = cellInfo => {
    return cellInfo.value === '' ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onHiding = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
    addSaleMasterRef.current.open(null);
  };

  const refresh = () => {
    gridRef.current.instance.refresh();
  };

  const onCancel = () => {
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    if (selectedItems.length === 0) {
      alert('취소할 대상을 선택해주세요.');
    } else {
      const ticketPayInfo = [];
      selectedItems.forEach(r => {
        ticketPayInfo.push(r.PayRequestStatusName);
      });
      const payRequestTicket = ticketPayInfo.some(val => ['결제완료', '요청완료'].includes(val));
      if (payRequestTicket) return alert('결제 요청된 매출은 취소할 수 없습니다.');
      const result = confirm('<i>취소하시겠습니까?</i>', '확인');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          const params = selectedItems.filter(i => i.Status !== 'C').map(i => i.TicketID);
          salesPayment
            .cancelEtcSalesHistory({
              TicketID: params.length === 1 ? params[0] : params,
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '취소되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                refresh();
                gridRef.current.instance.clearSelection();
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const onEdit = TicketID => {
    setIsOpen(true);
    addSaleMasterRef.current.open(TicketID);
  };

  const editCellRender = useCallback(e => {
    const { TicketID, Status, isEditable } = e.data.data;
    return (
      Status !== 'C' && (
        <Button
          className={isEditable === 'N' ? 'sales-confirm' : ''}
          icon="edit"
          type="normal"
          stylingMode="text"
          onClick={() => onEdit(TicketID)}
        />
      )
    );
  }, []);

  const detailCellRender = e => {
    const status = e.data.PayRequestStatusName;
    const isPaysys = status !== null && status !== '' && status !== undefined;
    return (
      <Button
        icon="search"
        width={30}
        type="normal"
        visible={isPaysys}
        stylingMode="text"
        onClick={() => {
          showPaymentDetailPopup(e.data);
        }}
      />
    );
  };

  const showPaymentDetailPopup = data => {
    setPaymentDetailPopup({ save: false, visible: !paymentDetailPopup.visible });
    paymentDetailRef.current.open(data.UID);
  };

  const onCellPrepared = useCallback(({ data, cellElement }) => {
    if (data) {
      const { Status } = data;
      const tr = cellElement.parentNode;
      if (Status === 'C' && tr) {
        tr.classList.add('sales-cancel');
        tr.classList.remove('dx-selection');
      }
    }
  }, []);

  // 확정
  const onConfirm = () => {
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    const checkedItems = selectedItems.filter(i => i.isEditable === 'N');
    if (selectedItems.length === 0) {
      alert('확정할 대상을 선택해주세요.');
    } else if (checkedItems.length > 0) {
      alert('이미 확정된 매출이 포함되어 있습니다');
    } else {
      const result = confirm('<i>확정하시겠습니까?</i>', '확인');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          const params = selectedItems.map(i => i.TicketID);
          salesPayment
            .confirmOtherTicketIssueHistory({
              TicketID: params,
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '확정되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                refresh();
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
    setSearchParams({
      ...searchParams,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
    setSearchParams({
      ...searchParams,
      ToDate: e.value,
    });
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const editCheckCellRender = e => {
    const isPCM = e.data.EtcSalesType === '013';
    const isValet = e.data.EtcSalesType === '006';
    const isTaxFree = e.data.IsTaxFree;
    return <CheckBox visible={isPCM || isValet} disabled={true} value={isTaxFree} />;
  };

  const showPaymentPopup = () => {
    const selectedRows = gridRef.current.instance.getSelectedRowsData();
    if (selectedRows.length === 0) {
      return alert('결제 요청할 매출을 선택해 주세요');
    } else if (selectedRows.length > 1) {
      return alert('결제 요청은 일괄 처리할 수 없습니다. 1건만 선택 해주세요.');
    }

    const { PaymentType, CustomerType, CustomerCorpName, CustomerTelephoneNo } = selectedRows[0];
    const isCorp = CustomerType === 'B';
    const memberName = isCorp && CustomerCorpName;
    const customerTelephoneNo = isCorp && CustomerTelephoneNo;

    const status = selectedRows[0].SalesStatus;
    const ticketPayInfo = [];
    selectedRows.forEach(r => {
      ticketPayInfo.push(r.PayRequestStatusName);
    });
    const payRequestTicket = ticketPayInfo.some(val => ['결제완료', '요청완료'].includes(val));
    /*if (payRequestTicket) {
        alert('이미 결제 요청 완료된 매출입니다.');
      } else*/
    if (!status) {
      alert('확정된 매출만 결제 요청 가능합니다.');
    } else if (PaymentType === 'TaxInvoice') {
      alert('세금계산서 매출은 결제 요청할 수 없습니다.');
    } else if (status !== 'N') {
      alert('대사 전인 매출을 선택해 주세요');
    } else {
      setPaymentPopup({ save: false, visible: !paymentPopup.visible });
      paymentRef.current.open({
        ...selectedRows[0],
        FromDate: null,
        ToDate: null,
        MemberName: memberName || '',
        MobilePhoneNo: customerTelephoneNo || '',
      });
    }
  };

  // const calculateSelectedRow = options => {
  //   if (options.name === 'SelectedRowsSummary') {
  //     if (options.summaryProcess === 'start') {
  //       options.totalValue = 0;
  //     }
  //     const isRowSelected = options.component.isRowSelected(options.value?.TicketID);
  //     if (options.summaryProcess === 'calculate' && isRowSelected) {
  //       options.totalValue += options.value.RefundPrice;
  //     }
  //   }
  //   if (options.name === 'SelectedRowsCount') {
  //     if (options.summaryProcess === 'start') {
  //       options.totalValue = 0;
  //     }
  //     const isRowSelected = options.component.isRowSelected(options.value?.TicketID);
  //     if (options.summaryProcess === 'calculate' && isRowSelected) {
  //       options.totalValue += 1;
  //     }
  //   }
  // };

  // const customizeSelectCount = e => {
  //   const result = gridRef.current.instance.getSelectedRowsData();
  //   const formattedTotal = '선택건수 ' + result.length + ' 건';
  //   return formattedTotal;
  // };
  // const customizeSelectTotal = e => {
  //   const result = gridRef.current.instance.getSelectedRowsData();
  //   const sum = result.reduce((total, item) => total + item.SalesPrice, 0);
  //   const formattedTotal = '선택합계 ' + sum.toLocaleString() + ' 원';
  //   return formattedTotal;
  // };

  // const formatCurrency = value => {
  //   return value.toLocaleString('ko-KR') + '원';
  // };
  // const formatCount = value => {
  //   return value + '건';
  // };
  // const onSelectionChanged = e => {
  //   const selectedRows = e.selectedRowsData;
  //   setSelectedRowdata(selectedRows);
  // };
  return (
    <React.Fragment>
      <div className={'content-block detail-title left-right-header'}>
        <div>
          <h2>기타 매출 관리</h2>
        </div>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={etcSalesMngManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>

      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div style={{ paddingTop: '5px' }}>
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card sales-history column-fix-grid'}
              dataSource={dataSource}
              showBorders={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
              height={700}
              width="100%"
              // onSelectionChanged={onSelectionChanged}
              // loadPanel={{ enabled: false }}
              isHeader={true}
              isExcel={true}
              excelOptions={{
                sheetName: 'sheet',
                fileName: '기타매출관리.xlsx',
                customizeCell: { date: ['RegisterDate'] },
              }}
              headerBefore={[
                <div className="label">매출일 :</div>,
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="FromDate"
                  onValueChanged={handleFromDate}
                  value={dateValue.FromDate}
                />,
                <span>~</span>,
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="ToDate"
                  min={dateValue.FromDate}
                  value={dateValue.ToDate}
                  onValueChanged={handleToDate}
                  dateOutOfRangeMessage="매출시작일보다 빠를 수 없습니다."
                />,
                <Autocomplete
                  labelMode="static"
                  stylingMode="outlined"
                  dataSource={autoDataSource}
                  value={autoText}
                  valueExpr="CostCenterName"
                  searchExpr="CostCenterName"
                  onValueChanged={onValueChanged}
                  onEnterKey={onEnterKey}
                  showClearButton={true}
                  width={250}
                  placeholder="사업소명을 입력하세요"
                  onItemClick={onItemClick}
                  mode="search"
                />,
                <SelectBox
                  labelMode="static"
                  placeholder="매출유형을 선택해주세요"
                  dropDownButtonTemplate="dropDownButton"
                  stylingMode="outlined"
                  dataSource={[
                    {
                      key: '전체',
                      items: [{ value: '', id: '매출유형 전체' }],
                    },
                    {
                      key: '매출유형',
                      items: etcType,
                    },
                  ]}
                  onItemClick={onClickEtcTypes}
                  valueExpr="value"
                  displayExpr="id"
                  width={200}
                  grouped={true}
                />,
                <TextBox
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="고객명"
                  onValueChanged={onChangeCustomer}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
              ]}
              headerAfter={[
                <Button text="매출 확정" onClick={onConfirm} />,
                <Button text="매출 등록" onClick={onOpen} />,
                <Button text="등록 취소" onClick={onCancel} />,
                <Button
                  icon="fas fa-comments-dollar"
                  text="결제 요청"
                  mode="default"
                  onClick={() => showPaymentPopup()}
                />,
                <Button icon="refresh" onClick={refresh} />,
              ]}
              onCellPrepared={onCellPrepared}
            >
              <Paging enabled={true} defaultPageSize={30} />
              <Scrolling mode="standard" />
              <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
              <Column
                dataField={'수정'}
                alignment={'center'}
                allowFiltering={false}
                allowExporting={false}
                cssClass="cell-remove-padding"
                cellComponent={editCellRender}
                width={50}
                fixed={true}
              />
              <Column dataField={'CostCenterName'} width={150} caption={'사업소'} fixed={true} />
              <Column dataField={'SalesDate'} width={100} caption={'매출일'} fixed={true} />
              <Column dataField={'EtcSalesTypeName'} width={100} caption={'매출유형'} fixed={true} />
              <Column dataField={'CustomerTypeName'} width={90} caption={'고객유형'} />
              <Column dataField={'CustomerName'} width={90} caption={'고객명'} />
              <Column dataField={'CustomerCorpName'} width={150} caption={'사업자명'} />
              <Column dataField={'PaymentTypeName'} width={90} caption={'결제유형'} />
              <Column dataField={'SalesPrice'} width={150} caption={'판매금액'} format="#,##0 원" />
              <Column dataField={'StatusName'} width={80} caption={'상태'} />
              <Column dataField={'SalesStatusName'} width={100} caption={'매출상태'} />
              <Column dataField={'AccountingSettlementNo'} caption={'현금영수증식별번호'} width={100} />
              <Column dataField={'PayRequestStatusName'} width={110} caption={'결제요청상태'} />
              <Column
                dataField={'상세보기'}
                width={100}
                caption={'상세보기'}
                cssClass="cell-remove-padding"
                cellRender={detailCellRender}
              />
              <Column dataField={'PayExpireDate'} width={120} caption={'결제만료일'} />

              <Column dataField={'AccountingDate'} width={150} caption={'대사일'} alignment={'left'} />
              <Column dataField={'Remark'} width={150} caption={'비고'} />
              <Column dataField={'FromDate'} width={100} caption={'시작일'} />
              <Column dataField={'ToDate'} width={100} caption={'종료일'} />
              <Column dataField={'Quantity'} width={80} caption={'수량'} alignment={'left'} />
              <Column dataField={'UnitPrice'} width={110} caption={'단가'} format="#,##0 원" />
              <Column dataField={'GarageArea'} width={110} caption={'차고지면적(㎡)'} format="#,##0" />
              <Column dataField={'SalesItemName'} width={120} caption={'장비항목명'} />
              <Column
                dataField={'IsTaxFree'}
                dataType="boolean"
                width={120}
                caption={'부가세 없음'}
                alignment="center"
                cellRender={editCheckCellRender}
              />
              <Column dataField={'RegisterDate'} width={150} caption={'등록일시'} cellRender={customizeText} />
              <Column dataField={'RegisterUser'} width={120} caption={'등록자'} />
              {/* <Summary calculateCustomSummary={calculateSelectedRow}>
                <TotalItem customizeText={customizeSelectCount} column="SalesPrice" showInColumn="SalesPrice" />
                <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="SalesPrice" />
              </Summary> */}
            </CommonDataGrid>
            <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="40%" height={825}>
              <ScrollView>
                <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
                  <AddSalesMaster
                    ref={addSaleMasterRef}
                    setIsOpen={setIsOpen}
                    etcType={etcType}
                    customerType={customerType}
                    paymentType={paymentType}
                    CostCenterCode={searchParams.CostCenterCode}
                    CostCenterName={searchParams.CostCenterName}
                    refresh={refresh}
                    isEtcSale
                    setLoadPanelVisible={setLoadPanelVisible}
                  />
                </div>
              </ScrollView>
            </Popup>
          </div>
        </div>
      </div>
      {/* 기타 매출 결제 요청 팝업 */}
      <Popup visible={paymentPopup.visible} showTitle={false} width="35%" height={700}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <PaymentForm
            ref={paymentRef}
            setPopup={setPaymentPopup}
            refresh={refresh}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>
      {/* 기타 매출 결제 요청 상세보기 팝업 */}
      <Popup visible={paymentDetailPopup.visible} showTitle={false} width="40%" height={800}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <PaymentDetailForm
            ref={paymentDetailRef}
            setPopup={setPaymentDetailPopup}
            refresh={refresh}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>

      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </React.Fragment>
  );
};

export default EtcSalesMaster;
