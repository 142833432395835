import React, { useRef, useImperativeHandle, useState, useCallback, useMemo, useEffect } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  Item,
  RequiredRule,
  ButtonItem,
  ButtonOptions,
  CustomRule,
  PatternRule,
  EmptyItem,
  EmailRule,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import { ticket } from 'api';
import { Tooltip } from 'devextreme-react/tooltip';
import { useDefaultInfo } from 'common/select/Types';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import CustomInfoPopup from 'pages/sales-mng/sales-taxinvoice/popup/CustomInfoPopup';
import { isWithin3Months } from 'common/validators/validators';
import { sortByKor } from 'utils/common';

const TicketMemberIssueForm = React.forwardRef((props, ref) => {
  const {
    selectPayType,
    selectType,
    selectStatus,
    selectDivision,
    selectUseYN,
    setPopup,
    refresh,
    setLoadPanelVisible,
  } = props;
  const [formData, setFormData] = useState({
    CarType: '',
    CostCenterCode: '',
    CostCenterName: '',
    EmailAddress: '',
    GroupCode: '',
    GroupName: '',
    MemberDivision: '',
    MemberDivisionName: '',
    MemberID: '',
    MemberName: '',
    MemberStatusName: '',
    MemberType: '',
    MemberTypeName: '',
    MobliePhoneNo: '',
    RegisterDate: '',
    Status: '',
    TagRentPrice: 0,
    TelephoneNo: '',
    UseTag: '',
    UseTagName: '',
    VehicleRegistration: '',
    PaymentType: '',
    PaymentStatus: '',
    FromDate: '',
    ToDate: '',
    Remark: '',
    SalesPrice: '',
    StandardPrice: '',
    // 사업자 정보 추가
    CustomerCorpRegNumber: '',
    CustomerCorpName: '',
    NameOfRepresentative: '',
    CustomerCode: '',
    //현금영수증 정보 추가
    DefaultIdentifierNo: '',
    DefaultTradeUsage: '',
    UseDefaultInfo: '',
  });
  const [dateValue, setDateValue] = useState();
  const [isTagActive, setIsTagActive] = useState(false);
  const [isTaxInvoice, setIsTaxInvoice] = useState(false);
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);
  const [selectMemberGroup, setSelectMemberGroup] = useState();
  const [isUsedCashbill, setIsUsedCashbill] = useState(false);

  // 레프
  const formRef = useRef({});
  const customInfoRef = useRef({});

  const taxRadioSelect = [
    {
      label: '발행',
      value: 1,
    },
    {
      label: '발행 안 함',
      value: 0,
    },
  ];

  const tradeUsageType = [
    {
      label: '소득공제용',
      value: 'A',
    },
    {
      label: '지출증빙용',
      value: 'B',
    },
  ];
  let isConfirmSave = false;

  useImperativeHandle(ref, () => ({
    open,
    onClose,
  }));

  const open = (CostCenterCode, CostCenterName) => {
    setFormData({
      CostCenterName,
      CostCenterCode,
      UseTag: 'N',
      Status: 'A',
      TaxYN: 0,
      DefaultTradeUsage: 'A',
      UseDefaultInfo: { data: { 0: false } },
    });

    setIsTaxInvoice(false);
    setIsTagActive(false);
    (async () => {
      await ticket
        .searchSeasonTicketMemberGroup({
          CostCenterCode: CostCenterCode,
        })
        .then(res => {
          if (res.isOk) {
            const groupType = res.data;
            const rawData = groupType.map(a => {
              return {
                text: a.GroupName,
                codeDesc: a.GroupName,
                code: a.GroupCode,
              };
            });
            rawData.unshift('');
            setSelectMemberGroup(sortByKor(rawData, 'codeDesc'));
          }
        });
    })();
  };

  useEffect(() => {
    if (selectedCustomInfo.length > 0) {
      setFormData({
        ...formData,
        CustomerCorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
        NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
      });
    }
  }, [selectedCustomInfo]);

  const selectPayTypeForTax = useMemo(() => {
    const res = selectPayType && selectPayType.filter(a => a.code === 'TaxInvoice');
    return res;
  }, [selectPayType]);

  const selectPayTypeExceptTax = useMemo(() => {
    const res = selectPayType && selectPayType.filter(a => a.code !== 'TaxInvoice');
    return res;
  }, [selectPayType]);

  const onClose = () => {
    setPopup({ save: false, visible: false });
    setIsTaxInvoice(false);
    setFormData({
      CarType: '',
      CostCenterCode: '',
      CostCenterName: '',
      EmailAddress: '',
      GroupCode: '',
      GroupName: '',
      MemberDivision: '',
      MemberDivisionName: '',
      MemberID: '',
      MemberName: '',
      MemberStatusName: '',
      MemberType: '',
      MemberTypeName: '',
      MobliePhoneNo: '',
      RegisterDate: '',
      Status: '',
      TagRentPrice: 0,
      TelephoneNo: '',
      UseTag: '',
      UseTagName: '',
      VehicleRegistration: '',
      PaymentType: '',
      PaymentStatus: 'A',
      FromDate: '',
      ToDate: '',
      Remark: '',
      SalesPrice: '',
      StandardPrice: '',
      // 사업자 정보 추가
      CustomerCorpRegNumber: '',
      CustomerCorpName: '',
      NameOfRepresentative: '',
      CustomerCode: '',
      DefaultIdentifierNo: '',
      DefaultTradeUsage: '',
      UseDefaultInfo: '',
    });
  };

  const handleDateFrom = e => {
    setDateValue(e.value);
    if (e.value) {
      setFormData({
        ...formData,
        ToDate: moment(e.value).endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  const dateFormat = date => {
    return moment(date).format('YYYY-MM-DD');
  };

  const onTaxTypeChanged = e => {
    if (e.value === 1) {
      setFormData({ ...formData, PaymentType: '', PaymentStatus: 'A' });
      setIsTaxInvoice(true);
    } else {
      setIsTaxInvoice(false);
    }
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        CostCenterCode,
        // MemberID,
        MemberName,
        MemberType,
        Status,
        VehicleRegistration,
        TelephoneNo,
        MobliePhoneNo,
        CarType,
        EmailAddress,
        GroupCode,
        MemberDivision,
        UseTag,
        TagRentPrice,
        // 추가됨
        PaymentType,
        PaymentStatus,
        FromDate,
        ToDate,
        Remark,
        // 추가됨
        StandardPrice,
        CustomerCode,
        DefaultTradeUsage,
        DefaultIdentifierNo,
        UseDefaultInfo,
        DefaultEmailAddress,
      } = formRef.current.props.formData;
      const useDefaultInfo = UseDefaultInfo.data[0];
      // 발행
      const paymentType = isTaxInvoice ? selectPayTypeForTax[0].code : 'Etc';

      const confirmYN = isConfirmSave ? 'Y' : 'N';
      const fromDate = dateFormat(FromDate);
      const toDate = dateFormat(ToDate);
      const isConfirmed = await isWithin3Months(fromDate, toDate);
      if (!isConfirmed) {
        return;
      }
      // 신규;
      const splitVehicleNumber = (fullnameStr, type) => {
        var result = '';
        if (type === 'prefix') result = fullnameStr.substr(0, fullnameStr.length - 4);
        else result = fullnameStr.substr(fullnameStr.length - 4, 4);
        return result.trim();
      };

      setLoadPanelVisible(true);
      const result = await ticket.insertSeasonTicketIssueHistoryWithNewMember({
        CostCenterCode,
        MemberName,
        MemberType,
        Status,
        VehicleRegistrationPlatePrefix: splitVehicleNumber(VehicleRegistration, 'prefix'),
        VehicleRegistrationPlateNo: splitVehicleNumber(VehicleRegistration, 'plate'),
        TelephoneNo,
        MobliePhoneNo,
        CarType,
        EmailAddress,
        GroupCode,
        MemberDivision,
        UseTag,
        TagRentPrice,
        PaymentType: paymentType,
        PaymentStatus: 'A',
        FromDate: fromDate,
        ToDate: toDate,
        Remark,
        StandardPrice,
        CustomerCode,
        ConfirmYN: confirmYN,
        DefaultTradeUsage,
        DefaultIdentifierNo,
        UseDefaultInfo: useDefaultInfo,
        DefaultEmailAddress,
      });
      if (result.isOk) {
        setLoadPanelVisible(false);
        notify(
          {
            message: '완료 되었습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'success',
        );
        setPopup({ save: true, visible: false });
        refresh('all');
      } else {
        setLoadPanelVisible(false);
        notify(
          {
            message: JSON.parse(result.error.detail).sqlMessage,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
      onClose();
    },
    [formData, isConfirmSave, isTaxInvoice],
  );

  const onFocusIn = e => {
    e.event.target.blur();
  };

  const digitCheck = useCallback(({ value }) => {
    var digitPart = value.substr(value.length - 4, 4).trim();
    if (!isNaN(digitPart) && digitPart.length === 4) {
      return true;
    }
  }, []);

  const onItemClick = e => {
    e.itemData.code === 'N' ? setIsTagActive(false) : setIsTagActive(true);
  };

  const onGroupItemClick = e => {
    if (e.itemData) {
      (async () => {
        const groupCode = e.itemData.code;
        const result = await ticket.searchSeasonTicketMemberGroupByGroupID({ GroupCode: groupCode });
        if (result.isOk > 0 && result.data) {
          const code = result.data[0].MemberDivision;
          setFormData({ ...formData, MemberDivision: code });
        }
      })();
    } else {
      setFormData({ ...formData, MemberDivision: '' });
    }
  };
  const LabelTemplate = () => {
    return (
      <>
        <div>
          <i
            id="helpedInfo"
            className="dx-icon dx-icon-help"
            style={{ fontSize: '14px', paddingTop: '9px', paddingLeft: '0px' }}
          ></i>
        </div>
        <Tooltip target="#helpedInfo" showEvent="mouseenter" hideEvent="mouseleave">
          <div id="tooltip-content" style={{ textAlign: 'left', fontSize: '13px' }}>
            * 식별번호 미 입력 시 자진발급번호(010-000-1234)로 신고됩니다.
            <br />
            소득공제용: 휴대폰 번호
            <br />
            지출증빙용: 사업자번호 or 휴대폰번호
          </div>
        </Tooltip>
      </>
    );
  };
  const onValueChanged = e => {
    const value = e.value;
    setIsUsedCashbill(value);
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container season-ticket-form">
          <Form
            ref={formRef}
            formData={formData}
            showColonAfterLabel={false}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem colCount={2}>
              <GroupItem>
                <GroupItem caption={'정기권 회원 추가'} colCount={6}>
                  <SimpleItem dataField="CostCenterName" colSpan={3} editorOptions={{ readOnly: true }}>
                    <Label text="사업소명" />
                  </SimpleItem>
                  <Item
                    dataField="Status"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectStatus,
                      displayExpr: 'text',
                      valueExpr: 'code',
                    }}
                    colSpan={3}
                  >
                    <Label text="회원 상태" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  <SimpleItem dataField="MemberName" colSpan={3}>
                    <Label text="회원명" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <Item
                    dataField="MemberType"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectType,
                      displayExpr: 'text',
                      valueExpr: 'code',
                    }}
                    colSpan={3}
                  >
                    <Label text="정기권 유형" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  <Item
                    dataField="GroupCode"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectMemberGroup,
                      displayExpr: 'text',
                      valueExpr: 'code',
                      onItemClick: onGroupItemClick,
                    }}
                    colSpan={3}
                  >
                    <Label text="회원 그룹" />
                  </Item>
                  <Item
                    dataField="MemberDivision"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectDivision,
                      displayExpr: 'text',
                      valueExpr: 'code',
                    }}
                    colSpan={3}
                  >
                    <Label text="회원 구분" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  <SimpleItem editorType="dxTextBox" dataField="VehicleRegistration" colSpan={3}>
                    <Label text="차량번호" />
                    <RequiredRule message="필수값 입니다." />
                    <CustomRule message={'차량번호 네 자리를 확인해주세요.'} validationCallback={digitCheck} />
                  </SimpleItem>
                  <SimpleItem dataField="MobliePhoneNo" colSpan={3}>
                    <RequiredRule message="필수값 입니다." />
                    <Label text="핸드폰번호" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="StandardPrice"
                    colSpan={3}
                    editorType="dxNumberBox"
                    editorOptions={{
                      showSpinButtons: true,
                      step: 1000,
                      min: 0,
                      format: '#,##0 원',
                    }}
                  >
                    <Label text="기준금액" />
                  </SimpleItem>
                  <SimpleItem dataField="EmailAddress" colSpan={3}>
                    <Label text="이메일" />
                  </SimpleItem>
                  <Item
                    dataField="UseTag"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectUseYN,
                      displayExpr: 'text',
                      valueExpr: 'code',
                      onItemClick: onItemClick,
                    }}
                    colSpan={3}
                  >
                    <Label text="태그사용유무" />
                  </Item>
                  <SimpleItem dataField="TagRentPrice" colSpan={3} editorOptions={{ disabled: !isTagActive }}>
                    <Label text="태그비용" />
                  </SimpleItem>
                  <SimpleItem dataField="TelephoneNo" colSpan={3}>
                    <Label text="전화번호" />
                  </SimpleItem>
                  <SimpleItem dataField="CarType" colSpan={3}>
                    <Label text="차종" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption="현금영수증 자동 발행" colCount={2}>
                  <SimpleItem
                    colSpan={1}
                    dataField="UseDefaultInfo.data[0]"
                    editorType="dxCheckBox"
                    editorOptions={{
                      items: useDefaultInfo,
                      valueExpr: 'value',
                      layout: 'horizontal',
                      text: `자동 발행 정보 직접 입력하기`,
                      hint: '자동 발행 기본값은 자진발급번호(010-000-1234)입니다.',
                      onValueChanged: onValueChanged,
                    }}
                  >
                    <Label text="정보 사용여부" visible={false}></Label>
                  </SimpleItem>
                  <SimpleItem
                    dataField="DefaultTradeUsage"
                    colSpan={2}
                    editorType="dxRadioGroup"
                    shouldRender="condition"
                    editorOptions={{
                      items: tradeUsageType,
                      valueExpr: 'value',
                      displayExpr: 'label',
                      layout: 'horizontal',
                      disabled: !isUsedCashbill,
                    }}
                  >
                    <Label text="거래구분" />
                  </SimpleItem>
                  <SimpleItem
                    cssClass="textbox-with-button"
                    dataField="DefaultIdentifierNo"
                    editorType="dxTextBox"
                    colSpan={1}
                    editorOptions={{
                      maxLength: 14,
                      disabled: !isUsedCashbill,
                      placeholder: '휴대전화/사업자등록번호',
                    }}
                  >
                    <PatternRule pattern={/^[\d-]+(\s+)?$/} message={'숫자와 - 기호만 입력 가능합니다.'} />
                    <Label text="식별번호" />
                  </SimpleItem>
                  <SimpleItem
                    cssClass="textbox-with-button"
                    dataField="DefaultEmailAddress"
                    editorType="dxTextBox"
                    colSpan={1}
                    editorOptions={{
                      disabled: !isUsedCashbill,
                      placeholder: '현금영수증을 전송할 이메일을 입력하세요.',
                    }}
                  >
                    <EmailRule message={'이메일 형식이 올바르지 않습니다.'} />
                    <Label text="E-mail" />
                  </SimpleItem>
                  {/* <SimpleItem cssClass="button-with-textbox" editorType="dxTextBox" render={LabelTemplate} /> */}
                </GroupItem>
              </GroupItem>

              <GroupItem>
                <GroupItem caption={'사업자 정보'} colCount={6}>
                  <SimpleItem
                    dataField="CustomerCorpRegNumber"
                    editorType="dxTextBox"
                    colSpan={3}
                    editorOptions={{
                      onFocusIn: onFocusIn,
                      buttons: [
                        {
                          name: 'trash',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            text: '사업자 선택',
                            onClick: () => {
                              customInfoRef.current.open();
                            },
                          },
                        },
                      ],
                      value: formData.CustomerCorpRegNumber,
                    }}
                  >
                    <Label text="사업자등록번호" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="CustomerCorpName"
                    editorType="dxTextBox"
                    colSpan={3}
                    editorOptions={{
                      readOnly: true,
                      value: formData.CustomerCorpName,
                    }}
                  >
                    <Label text="회사명" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="NameOfRepresentative"
                    editorType="dxTextBox"
                    colSpan={3}
                    editorOptions={{
                      readOnly: true,
                      value: formData.NameOfRepresentative,
                    }}
                  >
                    <Label text="대표자 성명" />
                  </SimpleItem>
                </GroupItem>
                <EmptyItem />
                <GroupItem>
                  <EmptyItem />
                </GroupItem>
                <GroupItem caption={'정기권 발행 정보'} colCount={6}>
                  {/* 결제유형 추가 */}
                  <SimpleItem
                    dataField="TaxYN"
                    colSpan={6}
                    editorType="dxRadioGroup"
                    shouldRender="condition"
                    editorOptions={{
                      items: taxRadioSelect,
                      valueExpr: 'value',
                      displayExpr: 'label',
                      layout: 'horizontal',
                      onValueChanged: onTaxTypeChanged,
                    }}
                  >
                    <RequiredRule message="필수 값입니다." />
                    <Label text="청구 세금계산서" />
                  </SimpleItem>
                  {/*  계약시작일 추가 */}
                  <Item
                    dataField="FromDate"
                    editorType="dxDateBox"
                    colSpan={3}
                    editorOptions={{
                      type: 'date',
                      dropDownButtonTemplate: 'dropDownButton',
                      displayFormat: 'yyyy-MM-dd',
                      useMaskBehavior: true,
                      icon: true,
                      onValueChanged: handleDateFrom,
                    }}
                  >
                    <Label text="계약시작일" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  <Item
                    dataField="ToDate"
                    editorType="dxDateBox"
                    colSpan={3}
                    editorOptions={{
                      type: 'date',
                      useMaskBehavior: true,
                      displayFormat: 'yyyy-MM-dd',
                      min: dateValue,
                      dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
                    }}
                  >
                    <Label text="계약종료일" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  <SimpleItem dataField="Remark" colSpan={6} editorType="dxTextArea">
                    <Label text="비고" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem>
                  <EmptyItem />
                </GroupItem>
                <GroupItem colCount={3}>
                  <ButtonItem>
                    <ButtonOptions
                      width={'80%'}
                      colSpan={1}
                      type={'default'}
                      text="즉시확정"
                      onClick={() => {
                        isConfirmSave = true;
                      }}
                      useSubmitBehavior={true}
                    ></ButtonOptions>
                  </ButtonItem>
                  <ButtonItem>
                    <ButtonOptions
                      colSpan={1}
                      width={'80%'}
                      type={'default'}
                      text="저장"
                      onClick={() => {
                        isConfirmSave = false;
                      }}
                      useSubmitBehavior={true}
                    ></ButtonOptions>
                  </ButtonItem>
                  <ButtonItem>
                    <ButtonOptions width={'80%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
      </form>
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
    </>
  );
});

export default TicketMemberIssueForm;
