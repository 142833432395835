import React, { useRef, useMemo, useState, useEffect } from 'react';
import CommonDataGrid from 'common/default-data-grid';
import ReportExport from './view-sales-cost-report-export';
import DateBox from 'devextreme-react/date-box';
import DataSource from 'devextreme/data/data_source';
import { TagBox } from 'devextreme-react/tag-box';
import moment from 'moment';
import axios from 'axios';
import { CheckBox } from 'devextreme-react/check-box';

import { Column, Scrolling } from 'devextreme-react/data-grid';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { Button } from 'devextreme-react/button';
import { common, salesPayment } from 'api';

import './sales-payment-report.scss';

const userId = localStorage.getItem('userId');

const ViewSalesCostReportPage = () => {
  const gridRef = useRef({});
  //검색
  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY-MM'),
    CostCenterCode: '',
    ByPaymentDate: 'N',
  });

  // lookup 사업소 목록
  const [costCenterType, setCostCenterType] = useState([]);
  // lookup 선택된 사업소
  const [costcenterCode, setCostcenterCode] = useState([]);
  // 운영 보고서 datagrid
  const [dataSource, setDataSource] = useState([]);

  // const [rawData, setRawData] = useState();
  const [costData, setCostData] = useState();

  useEffect(() => {
    (async () => {
      const result = await common.costcenterData();
      if (result.isOk) {
        setCostCenterType(result.data);
      }
    })();
  }, []);
  const onSearch = async () => {
    if (costcenterCode.length === 0) {
      return alert('사업소를 먼저 선택해주세요');
    }
    try {
      const costresult = await axios.post(`aggregation/getSalesFinalReport`, {
        ...searchData,
        CostCenterCode: costcenterCode.join(','),
        LoginID: userId,
      });
      // const result = await salesPayment.getCostReportRawData({
      //   ...searchData,
      //   CostCenterCode: selectedCostCenter.CostCenterCode,
      //   LoginID: userId,
      // });
      setDataSource(costresult.data[0]);
      setCostData(costresult.data);
      // setRawData(result.data);
    } catch (e) {
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  };

  const onPaymentDateCheckValueChanged = e => {
    setSearchData({
      ...searchData,
      ByPaymentDate: e.value ? 'Y' : 'N',
    });
  };

  const handleSalesMonth = e => {
    const yyyymmFormat = moment(e.value).format('YYYY-MM');
    setSearchData({
      ...searchData,
      SalesMonth: yyyymmFormat,
    });
  };

  const onClickButton = e => {
    const result = [...costcenterCode];
    const add = e.addedItems.map(a => a.CostCenterCode);
    const remove = e.removedItems.map(a => a.CostCenterCode);
    if (add.length > 0) {
      result.push(...add);
      setCostcenterCode(result);
    }
    if (remove.length > 0) {
      const newArr = result.filter(a => !remove.includes(a));
      setCostcenterCode(newArr);
    }
  };
  const onSelectionChanged = e => {
    setCostcenterCode(e.selectedItem);
  };

  const groupedData = new DataSource({
    store: costCenterType,
    key: 'CostCenterCode',
  });

  return (
    <div>
      <h2 className={'content-block detail-title'}>사업소 운영 보고서</h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={300}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '사업소 확정 매출 조회 목록.xlsx',
            }}
            headerBefore={[
              <div className="label">등록월 :</div>,
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
                width={100}
                onValueChanged={handleSalesMonth}
                value={searchData.SalesMonth}
                elementAttr={{ id: 'search-date-box' }}
                // label={'매출등록월'}
              />,
              <TagBox
                dataSource={costCenterType}
                showSelectionControls={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                maxDisplayedTags={5}
                displayExpr="CostCenterName"
                valueExpr="CostCenterCode"
                placeholder="조회할 사업소를 모두 선택해주세요"
                stylingMode="outlined"
                onSelectionChanged={onClickButton}
                searchEnabled={true}
                width={650}
                selectAllText={'전체'}
                multiline={false}
              />,
              // <Lookup
              //   labelMode="static"
              //   stylingMode="outlined"
              //   dataSource={groupedData}
              //   valueExpr="CostCenterName"
              //   displayExpr="CostCenterName"
              //   searchExpr="CostCenterName"
              //   searchEnabled={true}
              //   dropDownCentered={false}
              //   width={300}
              //   placeholder="검색어를 입력해주세요"
              //   onSelectionChanged={onSelectionChanged}
              //   mode="search"
              // >
              //   <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
              // </Lookup>,
            ]}
            headerAfter={[
              <CheckBox
                defaultValue={false}
                text="수금일 기준 조회   "
                onValueChanged={onPaymentDateCheckValueChanged}
              />,
              <div>{`(VAT 제외 비용)`}</div>,
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'SalesMonth'} caption={'매출월'} alignment={'center'} width={'100'} />
            <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} width={'110'} />
            <Column caption={'매출'} alignment={'center'}>
              <Column dataField={'Sales-GeneralSales'} caption={'일반권'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-SeasonTicket'} caption={'정기권'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-DCTicket'} caption={'할인권'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-EtcSales'} caption={'기타'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-BtoB'} caption={'BtoB'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-Total'} caption={'매출합계'} alignment={'left'} format="#,##0 원" />
            </Column>
            <Column caption={'운용비용'} alignment={'center'}>
              <Column dataField={'Cost-Fixed'} caption={'고정비'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Cost-Floating'} caption={'변동비'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Cost-Total'} caption={'비용합계'} alignment={'left'} format="#,##0 원" />
            </Column>
            <Column caption={'수익'} alignment={'center'}>
              <Column dataField={'Profit-Total'} caption={'수익합계'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Profit-User'} caption={'갑사수익'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Profit-Our'} caption={'자사수익'} alignment={'left'} format="#,##0 원" />
            </Column>
            {/* <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
            <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'center'} /> */}
          </CommonDataGrid>
          <ReportExport costData={costData} searchData={searchData} />
        </div>
      </div>
    </div>
  );
};

export default ViewSalesCostReportPage;
