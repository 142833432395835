import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Column, Selection, Editing, Scrolling, LoadPanel } from 'devextreme-react/tree-list';
import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';

import { makeTaxInvoiceStatusCellRender } from 'common/popup/sales-common-util';
import { getNormalDateFormat } from 'utils/common';
import { salesPayment, error } from '../../../../api';

import CustomStore from 'devextreme/data/custom_store';
import ScrollView from 'devextreme-react/scroll-view';
import DateBox from 'devextreme-react/date-box';

import DetailHistory from '../popup/DetailHistory';
import CommonTreeList from 'common/default-tree-list';
import moment from 'moment';
import EditPopup from '../popup/EditPopup';
import notify from 'devextreme/ui/notify';

const RentalSalesTaxInvoice = () => {
  const COSTCENTER = 'RENTL';
  const treeRef = useRef({});
  const detailHistoryRef = useRef({});
  const editRef = useRef({});

  const [editMode, setEditMode] = useState('HEADER_NO_DETAIL');
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    ToDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const thisMonth = moment(new Date()).format('yyyy-MM');
  const [targetMonth, setTargetMonth] = useState(thisMonth);
  const [localData, setLocalData] = useState([]);

  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const handleTargetMonth = e => {
    const salesMonth = moment(getNormalDateFormat(e.value)).format('YYYY-MM');
    setTargetMonth(salesMonth);
  };

  const editCellRender = e => {
    const { TaxInvoiceStatusName, KeyID } = e.data;
    const isDetail = KeyID.startsWith('D');
    if (TaxInvoiceStatusName !== '대기중' && !isDetail) {
      return <Button icon="search" type="normal" stylingMode="contained" onClick={() => togglePopup(e.data)} />;
    }
  };
  const taxInvoiceStatusCellRender = useCallback(e => {
    return makeTaxInvoiceStatusCellRender(e);
  }, []);

  useEffect(() => {
    callApi();
  }, []);

  const callApi = useCallback(() => {
    (async () => {
      const result = await salesPayment.getRentalSalesInvoiceHistory({
        FromDate: dateValue.FromDate,
        ToDate: dateValue.ToDate,
      });
      if (result.isOk) {
        const treeDetail = result.data[1];
        const treeHeader = result.data[0];
        const treeData = groupByKey(treeHeader, treeDetail, 'InvoiceID', 'InvoiceID');
        setLocalData(treeData);
        treeRef.current.instance.deselectAll();
      }
    })();
  }, [dateValue]);

  const onSearch = () => {
    callApi();
  };

  const refresh = () => {
    callApi();
  };

  const refreshAll = () => {
    callApi();
    treeRef.current.instance.deselectAll();
    treeRef.current.instance.clearSorting();
    treeRef.current.instance.clearFilter();
  };
  const onCreateonRefreshWithAutoGeneratedClick = searchParam => {
    (async () => {
      const result = await salesPayment.getRentalSalesInvoiceHistory({
        FromDate: dateValue.FromDate,
        ToDate: dateValue.ToDate,
        CurrentMonth: targetMonth,
        DDayCount: 0,
      });
      if (result.isOk) {
        const treeHeader = result.data[0];
        const treeDetail = result.data[1];
        const treeData = groupByKey(treeHeader, treeDetail, 'InvoiceID', 'InvoiceID');
        setLocalData(treeData);
        treeRef.current.instance.deselectAll();
        notify(
          {
            message: `${targetMonth}월 세금계산서 목록이 임시 생성되었습니다.`,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'success',
        );
      } else {
        notify(
          {
            message: error.errorMsgCheck(result.error.detail),
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };

  const groupByKey = (headerData, detailData, key, detailKey) => {
    return headerData.map(headerItem => {
      const matchingDetails = detailData.filter(detailItem => detailItem[detailKey] === headerItem[key]);
      if (matchingDetails.length > 1) {
        return {
          ...headerItem,
          IsHeader: 'Y',
          items: matchingDetails,
        };
      }
      return headerItem;
    });
  };

  const ParseFloat = (fieldNames, data) => {
    const newData = { ...data };
    fieldNames.forEach(field => {
      if (data.hasOwnProperty(field)) {
        newData[field] = parseFloat(data[field]) || 0;
      }
    });
    return newData;
  };

  const getRowTotal = (fieldNames, data) => {
    const total = fieldNames.reduce((sum, field) => sum + (data[field] || 0), 0);
    return Math.round(total);
  };

  const getParentTotal = (fieldNames, parent, child) => {
    const newData = { ...parent };
    fieldNames.forEach(field => {
      if (parent.hasOwnProperty(field)) {
        const sum = child.reduce((acc, item) => acc + item[field], 0);
        newData[field] = Math.round(sum);
      }
    });
    return newData;
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: () => {
        return localData;
      },
      update: (key, value) => {
        setLocalData(prevData =>
          prevData.map(item => {
            const fields = [
              'StandardPriceDeviceRental',
              'StandardPriceConstruction',
              'StandardPriceMaintenance',
              'StandardPriceCallCenter',
            ];
            // 부모노드 변경
            if (item.KeyID === key) {
              const merge = { ...item, ...value };
              const parse = ParseFloat(fields, merge);
              const newTotal = getRowTotal(fields, merge);
              const newTotalWithVAT = Math.round(newTotal * 1.1);
              const newItem = { ...parse, StandardPriceTotal: newTotal, TotalInvoicePrice: newTotalWithVAT };

              // 자식노드가 있는 경우
              if (item.items) {
                // 부모노드 변경사항 -> 자식 노드를 강제 변경
                let propagation = { isPropagated: false, SalesDate: '' };
                propagation.isPropagated = !!value.SalesDate;
                propagation.SalesDate = value.SalesDate;
                if (propagation.isPropagated) {
                  item.items.forEach(subItem => {
                    if (subItem.hasOwnProperty('SalesDate')) {
                      subItem.SalesDate = propagation.SalesDate;
                    }
                  });
                }
              }
              return newItem;
            }
            // 자식노드 변경
            else if (item.items) {
              let recursive = { isRecursive: false, TotalInvoicePrice: '' };
              const children = item.items.map(subItem => {
                // 자식노드 중 변경되는 노드
                if (subItem.KeyID === key) {
                  // 자식노드에 변경사항이 있는경우 무조건 부모노드를 변경
                  recursive.isRecursive = true;
                  const merge = { ...subItem, ...value };
                  const parse = ParseFloat(fields, merge);
                  const newTotal = getRowTotal(fields, merge);
                  // 자식노드의 회차 총 발행금액은 부모 값에 의해 강제 변경되므로 변경될 필요 없음
                  const newSubItem = { ...parse, StandardPriceTotal: newTotal };
                  return newSubItem;
                }
                // 자식노드 중 변경되지 않는 노드
                return subItem;
              });
              const addFields = [...fields, 'StandardPriceTotal'];
              let newParent = getParentTotal(addFields, item, children);
              // 자식노드의 변경으로 인한 재귀 변경 범위 : 나/형제/부모
              if (recursive.isRecursive) {
                recursive.TotalInvoicePrice = Math.round(newParent.StandardPriceTotal * 1.1);
                children.forEach(item => {
                  if (item.hasOwnProperty('TotalInvoicePrice')) {
                    item.TotalInvoicePrice = recursive.TotalInvoicePrice;
                  }
                });
                newParent = {
                  ...newParent,
                  TotalInvoicePrice: recursive.TotalInvoicePrice,
                };
              }
              return { ...newParent, items: children };
            }
            // 부모노드 중 변경되지 않는 노드
            return item;
          }),
        );
      },
    });
    return customDataSource;
  }, [localData]);

  const togglePopup = data => {
    detailHistoryRef.current.open(data.InvoiceID);
    setIsOpen(true);
  };

  const onHiding = () => {
    setIsOpen(false);
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };

  const onCellPrepared = useCallback(({ data, cellElement, column }) => {
    // 상태에 따라 selection, edit 표시
    if (data && cellElement && column) {
      const { TaxInvoiceStatusName, IsHeader, KeyID } = data;
      const isDetail = KeyID.startsWith('D');
      const tr = cellElement.parentNode;
      if (column.command === 'edit') {
        if (TaxInvoiceStatusName !== '대기중') {
          cellElement.innerHTML = '';
        }
      }
      if (tr && isDetail) {
        const checkBoxIconElement = cellElement.querySelector('.dx-checkbox-icon');
        if (checkBoxIconElement) {
          checkBoxIconElement.style.display = 'none';
        }
        cellElement.classList.add('no-selection');
      }
    }
  }, []);

  const handleConfirm = async () => {
    const selectedRowsData = treeRef.current.instance.getSelectedRowsData('all');
    const validStatus = selectedRowsData.every(a => a.TaxInvoiceStatusName === '대기중');
    const length = selectedRowsData.length;
    if (length === 0) {
      return alert('확정할 세금계산서를 선택하세요');
    }
    if (!validStatus) {
      return alert('대기중인 목록만 확정할 수 있습니다.');
    }
    const res = await confirm('선택된 목록에 대해 세금계산서를 발행하겠습니까?', '확인');
    if (res) {
      console.log('선택된 목록을 표시함>', selectedRowsData);
      // await api
      setLoadPanelVisible(true);
      const result = await salesPayment.insertNewRentalInvoice({
        RequestRentalInvoiceList: selectedRowsData,
      });
      if (result.isOk) {
        setLoadPanelVisible(false);
        refresh();
        notify(
          {
            message: '발행 되었습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'success',
        );
      } else {
        setLoadPanelVisible(false);
        notify(
          {
            message: error.errorMsgCheck(result.error.detail),
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
        return;
      }
    }
  };
  const cellRender = ({ data, row }) => {
    const { HiParkingID } = data;
    if (row && row.isEditing) {
      return <div style={{ textAlign: 'center' }}>{HiParkingID}</div>;
    }
    return HiParkingID;
  };

  const onSelectionChanged = e => {
    const selectedRows = e.selectedRowsData;
    const selectedParentKeys = new Set(selectedRows.filter(row => row.IsHeader).map(row => row.KeyID));

    // 전체 부모노드를 순회하면서 현재 선택된 key가 있는 경우에는 자식을 select, key가 없는 경우에는 자식을 deselect
    localData.forEach(item => {
      if (item.IsHeader && item.items) {
        const childrenKeys = item.items.map(child => child.KeyID);
        if (selectedParentKeys.has(item.KeyID)) {
          treeRef.current.instance.selectRows(childrenKeys, true);
        } else {
          treeRef.current.instance.deselectRows(childrenKeys, true);
        }
      }
    });
  };

  const handleEdit = async () => {
    const header = treeRef.current.instance.getSelectedRowsData('excludeRecursive');
    const validStatus = header.every(a => a.TaxInvoiceStatusName === '대기중');
    const length = header.length;
    if (length === 0) {
      return alert('수정할 계산서를 선택해주세요');
    }
    if (!validStatus) {
      return alert('대기중인 목록만 수정할 수 있습니다.');
    }
    editRef.current.open(length);
    setIsEditOpen(true);
    const result = await editRef.current.submit();
    if (!result) {
      return;
    }
    const SalesDate = result;
    setLocalData(prevData => {
      return prevData.map(item => {
        // 선택된 헤더 데이터의 KeyID와 일치하는 경우
        if (header.some(header => header.KeyID === item.KeyID)) {
          if (item.items) {
            return {
              ...item,
              SalesDate,
              items: item.items.map(subItem => {
                // 하위 항목도 업데이트
                return {
                  ...subItem,
                  SalesDate,
                };
              }),
            };
          } else {
            return {
              ...item,
              SalesDate,
            };
          }
        }
        return item;
      });
    });
  };

  const calculateMonthlySalesDay = rowData => {
    if (rowData.MonthlySalesDay.endsWith('일')) {
      return rowData.MonthlySalesDay;
    }
    return `${rowData.MonthlySalesDay}일`;
  };

  const onEditingStart = e => {
    if (e.key.startsWith('H')) {
      if (e.data.IsHeader === 'Y') {
        setEditMode('HEADER_HAS_DETAIL');
      } else {
        setEditMode('HEADER_NO_DETAIL');
      }
    } else {
      setEditMode('DETAIL');
    }
  };

  return (
    <div>
      <div className={'dx-card responsive-paddings native-scroll-header grid-with-status'}>
        <CommonTreeList
          treeRef={treeRef}
          className={'dx-card wide-card'}
          onCellPrepared={onCellPrepared}
          dataSource={dataSource}
          showBorders={true}
          wordWrapEnabled={true}
          hoverStateEnabled={true}
          itemsExpr="items"
          dataStructure="tree"
          isExcel={true}
          isHeader={true}
          onSelectionChanged={onSelectionChanged}
          onEditingStart={onEditingStart}
          excelOptions={{
            sheetName: 'sheet',
            fileName: '렌탈 세금계산서 발행 관리.xlsx',
          }}
          headerBefore={[
            <div className="label">발행요청일:</div>,
            <DateBox
              type="date"
              displayFormat="yyyy-MM-dd"
              stylingMode="outlined"
              dropDownButtonTemplate={'dropDownButton'}
              useMaskBehavior={true}
              icon={true}
              dataField="FromDate"
              onValueChanged={handleFromDate}
              value={dateValue.FromDate}
            />,
            <span> ~ </span>,
            <DateBox
              type="date"
              displayFormat="yyyy-MM-dd"
              stylingMode="outlined"
              dropDownButtonTemplate={'dropDownButton'}
              useMaskBehavior={true}
              icon={true}
              dataField="ToDate"
              min={dateValue.FromDate}
              value={dateValue.ToDate}
              onValueChanged={handleToDate}
              dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
            />,
            <Button text="조회" onClick={onSearch} />,
            <div className="label">임시생성기준월 :</div>,
            <DateBox
              type="date"
              pickerType="calendar"
              displayFormat="yyyy-MM"
              dataField="date"
              value={targetMonth}
              onValueChanged={handleTargetMonth}
              stylingMode="outlined"
              icon={true}
              calendarOptions={{
                maxZoomLevel: 'year',
              }}
            />,
            <Button text="임시생성" onClick={onCreateonRefreshWithAutoGeneratedClick} />,
          ]}
          headerAfter={[
            <Button icon="edit" text="일괄수정" type="normal" onClick={handleEdit} />,
            <Button icon="check" text="확정" type="default" onClick={handleConfirm} />,
            <Button
              icon="refresh"
              type="normal"
              stylingMode="contained"
              onClick={() => {
                refreshAll();
              }}
            />,
          ]}
          columnAutoWidth={true}
          height={650}
        >
          <Editing mode="row" allowAdding={false} allowDeleting={false} allowUpdating={true} />
          <Scrolling useNative={true} />
          <Selection mode="multiple" recursive={false} allowSelectAll={true} />
          <Column dataField={'RentalKeyID'} caption={'RentalKeyID'} width={80} visible={false} />
          <Column
            dataField={'CostCenterCode'}
            dataType="string"
            caption={'CostCenterCode'}
            width={80}
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField={'HiParkingID'}
            caption={'P코드'}
            dataType="string"
            width={150}
            fixed={true}
            allowEditing={false}
            cellRender={cellRender}
          />
          <Column
            dataField={'CostCenterName'}
            caption={'사업소명'}
            dataType="string"
            width={180}
            fixed={true}
            allowEditing={false}
          />
          <Column
            dataField={'CustomerCode'}
            caption={'SAP사업자코드'}
            dataType="string"
            width={5}
            fixed={true}
            allowEditing={false}
            visible={false}
          />
          <Column
            dataField={'CustomerCorpNumber'}
            caption={'사업자번호'}
            width={5}
            dataType="string"
            fixed={true}
            allowEditing={false}
            visible={false}
          />
          <Column
            dataField={'CustomerName'}
            caption={'사업자명'}
            dataType="string"
            width={120}
            fixed={true}
            allowEditing={false}
          />
          <Column
            dataField={'TaxInvoiceStatusName'}
            cssClass="cell-remove-padding"
            caption={'상태'}
            dataType="string"
            alignment={'center'}
            width={110}
            cellRender={taxInvoiceStatusCellRender}
            fixed={true}
            allowEditing={false}
          />
          <Column
            dataField={'사업소계산서수'}
            caption={'사업소 계산서수'}
            width={85}
            dataType="string"
            allowEditing={false}
            fixed={true}
          />
          <Column
            dataField={'계산서항목수'}
            caption={'계산서 항목수'}
            width={80}
            calculateCellValue={rowData => `항목 ${rowData.계산서항목수}건`}
            dataType="string"
            allowEditing={false}
            fixed={true}
          />
          <Column
            allowEditing={editMode === 'HEADER_NO_DETAIL' || editMode === 'HEADER_HAS_DETAIL'}
            dataField={'SalesDate'}
            dataType="date"
            caption={'발행요청일'}
            width={100}
            fixed={true}
            editorOptions={
              {
                // min: moment().startOf('month').format('YYYY-MM-DD'),
                // max: moment().endOf('month').format('YYYY-MM-DD'),
              }
            }
          />
          <Column
            cssClass="cell-remove-padding"
            dataField={'detail'}
            caption={'상세'}
            alignment={'center'}
            width={50}
            cellRender={editCellRender}
            allowFiltering={false}
            allowExporting={false}
            dataType="string"
            allowEditing={false}
            fixed={true}
          />
          <Column
            dataField={'ContractStartDate'}
            caption={'계약개시일'}
            fixed={false}
            width={100}
            dataType="date"
            allowEditing={false}
          />
          <Column
            dataField={'ContractEndDate'}
            dataType="date"
            caption={'계약종료일'}
            fixed={false}
            width={100}
            allowEditing={false}
          />
          <Column caption={'회차 구분'} alignment={'center'} fixed={false}>
            <Column
              dataField={'LastSeq'}
              caption={'마지막'}
              width={75}
              allowEditing={false}
              dataType="string"
              calculateCellValue={rowData => `${rowData.LastSeq}회`}
            />
            <Column
              dataField={'ExpireSeq'}
              caption={'만기'}
              cssClass={''}
              dataType="string"
              width={75}
              allowEditing={false}
              calculateCellValue={rowData => `${rowData.ExpireSeq}회`}
            />
          </Column>{' '}
          <Column
            dataField={'MonthlySalesDay'}
            dataType="string"
            caption={'정기발행일'}
            width={100}
            fixed={false}
            allowEditing={false}
            calculateCellValue={calculateMonthlySalesDay}
          />
          <Column
            allowEditing={editMode === 'HEADER_NO_DETAIL' || editMode === 'DETAIL'}
            dataField={'StandardPriceDeviceRental'}
            caption={'렌탈료'}
            dataType="number"
            width={100}
            fixed={false}
            format="#,##0 원"
          />
          <Column
            allowEditing={editMode === 'HEADER_NO_DETAIL' || editMode === 'DETAIL'}
            dataField={'StandardPriceConstruction'}
            caption={'공사료'}
            dataType="number"
            width={100}
            fixed={false}
            format="#,##0 원"
          />
          <Column
            allowEditing={editMode === 'HEADER_NO_DETAIL' || editMode === 'DETAIL'}
            dataField={'StandardPriceMaintenance'}
            caption={'유지보수료'}
            dataType="number"
            width={100}
            fixed={false}
            format="#,##0 원"
          />
          <Column
            allowEditing={editMode === 'HEADER_NO_DETAIL' || editMode === 'DETAIL'}
            dataField={'StandardPriceCallCenter'}
            caption={'콜센터료'}
            dataType="number"
            width={100}
            fixed={false}
            format="#,##0 원"
          />
          <Column
            allowEditing={false}
            dataField={'StandardPriceTotal'}
            caption={'월총공급가'}
            dataType="number"
            width={100}
            fixed={false}
            format="#,##0 원"
          />
          <Column
            allowEditing={false}
            dataField={'CurrentSeq'}
            caption={'회차'}
            dataType="string"
            width={70}
            // calculateCellValue={rowData => `${rowData.CurrentSeq}회`}
          />
          <Column
            allowEditing={false}
            dataField={'SapStatus'}
            dataType="string"
            caption={'SapStatus'}
            width={110}
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField={'TotalInvoicePrice'}
            caption={'회차총발행금액'}
            dataType="string"
            width={130}
            format="#,##0 원"
          />
          <Column
            allowEditing={false}
            dataField={'ManagementName'}
            dataType="string"
            caption={'계산서구분자'}
            width={150}
          />
          <Column allowEditing={false} dataField={'InvoiceID'} dataType="string" caption={'계산서번호'} width={150} />
          <Column
            allowEditing={editMode === 'HEADER_HAS_DETAIL' || editMode === 'HEADER_NO_DETAIL'}
            dataField={'InvoiceRemark'}
            dataType="string"
            caption={'계산서 적요'}
            width={150}
          >
            <Button />
          </Column>
          <Column
            allowEditing={editMode === 'HEADER_NO_DETAIL' || editMode === 'DETAIL'}
            dataField={'ItemName'}
            dataType="string"
            caption={'계산서항목명'}
            width={150}
          />
          <Column
            allowEditing={editMode === 'HEADER_NO_DETAIL' || editMode === 'DETAIL'}
            dataField={'ItemRemark'}
            dataType="string"
            caption={'항목비고'}
            width={150}
          />
          <Column
            allowEditing={false}
            dataField={'ItemSalesPrice'}
            caption={'계정발행금액'}
            dataType="number"
            width={130}
            format="#,##0 원"
          />
          <Column dataField={'NewSalesID015'} caption={'NewSalesID015'} dataType="string" visible={false} />
          <Column dataField={'NewSalesID016'} caption={'NewSalesID016'} dataType="string" visible={false} />
          <Column dataField={'NewSalesID017'} caption={'NewSalesID017'} dataType="string" visible={false} />
          <Column dataField={'NewSalesID018'} caption={'NewSalesID018'} dataType="string" visible={false} />
          <Column dataField={'ItemJson'} caption={'ItemJson'} dataType="string" visible={false} />
          <Column
            dataField={'InvoiceCustomerCode'}
            caption={'InvoiceCustomerCode'}
            dataType="string"
            width={200}
            visible={false}
          />
          <Column
            allowEditing={false}
            dataField={'InvoiceCustomerCorpNumber'}
            caption={'계산서사업자번호'}
            dataType="string"
            width={150}
          />
          <Column dataField={'CustomerEmail1'} dataType="string" caption={'이메일'} width={400} allowEditing={false} />
          <Column type="buttons" fixed={true}>
            <Button name="edit" />
          </Column>
        </CommonTreeList>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
      {/* 세금계산서 상세보기 */}
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="55%" height={890}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '0px 20px' }}>
            <DetailHistory
              ref={detailHistoryRef}
              setIsOpen={setIsOpen}
              setLoadPanelVisible={setLoadPanelVisible}
              refresh={refresh}
            />
          </div>
        </ScrollView>
      </Popup>
      {/* 세금계산서 일괄 수정 */}
      <Popup onHiding={onHiding} visible={isEditOpen} showTitle={false} width="25%" height={310}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <EditPopup
            ref={editRef}
            setPopup={setIsEditOpen}
            refresh={refresh}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>
    </div>
  );
};

export default RentalSalesTaxInvoice;
