import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  PatternRule,
  EmptyItem,
  EmailRule,
} from 'devextreme-react/form';
import { cashbillTypeRadioSelect, cashbillTradeTypeSelect } from 'common/select/Types';
import { cancelCashbillAPI, checkCashbillAPI, confirmCancellation } from 'common/validators/validators';
import { aggregation } from 'api';

import { Tooltip } from 'devextreme-react/tooltip';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import 'devextreme-react/text-area';

const AddCashbill = React.forwardRef((props, ref) => {
  const { setIsOpen, refresh, setLoadPanelVisible } = props;
  const [formData, setFormData] = useState();
  const [rawdata, setRawdata] = useState({});
  const formRef = useRef({});

  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = async selectedData => {
    if (selectedData.length === 0) return;
    const invoker = 'RegisterCashbill';
    const dataLength = selectedData.length;
    const keyIDList = selectedData.map(a => a.KeyID);
    const defaultValues = getDefaultValues(selectedData);
    const sumCashPrice = getSumValue(selectedData, 'CashPrice');
    const maxCashLastPayDate = getLastDateTimeValue(selectedData, 'CashLastPayDate');
    setLoadPanelVisible(true);
    const confirmIdArr = await checkCashbillAPI(keyIDList, invoker);
    const confirmIdLength = confirmIdArr.length;

    if (!confirmIdArr) {
      setLoadPanelVisible(false);
      return;
    }
    if (confirmIdLength > 0) {
      setLoadPanelVisible(false);
      const isConfirmed = await confirmCancellation(confirmIdArr);
      if (!isConfirmed) {
        return;
      }
      setLoadPanelVisible(true);
      const cancelResult = await cancelCashbillAPI(confirmIdArr);
      if (!cancelResult) {
        setLoadPanelVisible(false);
        return;
      } else {
        refresh();
      }
    }

    setLoadPanelVisible(false);
    setIsOpen(true);
    setRawdata({
      KeyID: keyIDList,
      CashPrice: sumCashPrice,
      CashLastPayDate: maxCashLastPayDate,
      ...defaultValues,
      TradeType: 'A', // 고정
      DataLength: dataLength,
    });
    setFormData({
      KeyID: keyIDList,
      CashPrice: sumCashPrice,
      CashLastPayDate: maxCashLastPayDate,
      ...defaultValues,
      TradeType: 'A', // 고정
    });
  };

  const getSumValue = (arr, key) => {
    const res = arr.reduce((acc, obj) => {
      const val = obj[key] || 0;
      return acc + val;
    }, 0);
    return res;
  };

  const getLastDateTimeValue = arr => {
    const res = arr.reduce(
      (acc, obj) => {
        const str = obj.CashLastPayDate;
        const lastDateTime = new Date(str);
        return lastDateTime > acc.date ? { date: lastDateTime, value: str } : acc;
      },
      { date: new Date(arr[0].CashLastPayDate), value: arr[0].CashLastPayDate },
    );
    return res.value;
  };

  const getDefaultValues = arr => {
    const { DefaultEmailAddress, DefaultIdentifierNo, DefaultTradeUsage, MobliePhoneNo, CorpRegNumber } = arr[0];
    const defaultValues = {
      TradeUsage: DefaultTradeUsage,
      EmailAddress: DefaultEmailAddress,
      IdentifierNo: DefaultIdentifierNo,
    };
    const emptyValues = {
      TradeUsage: 'A',
      EmailAddress: '',
      IdentifierNo: '',
    };
    const memberValues = {
      TradeUsage: 'A',
      IdentifierNo: MobliePhoneNo,
      CorpRegNumber: CorpRegNumber,
    };
    const isDefaultInfoNull = Object.values({
      DefaultEmailAddress,
      DefaultIdentifierNo,
    }).every(value => value === null || value === '' || value === undefined);

    // 복수 선택
    if (arr.length > 1) {
      const diffSet1 = new Set(arr.map(item => item.DefaultIdentifierNo));
      const diffSet2 = new Set(arr.map(item => item.DefaultEmailAddress));
      if ([diffSet1, diffSet2].some(set => set.size > 1)) {
        const res = emptyValues;
        return res;
      } else {
        const res = isDefaultInfoNull ? emptyValues : defaultValues;
        return res;
      }
    }
    // 단일 선택
    else {
      const res = isDefaultInfoNull ? memberValues : defaultValues;
      return res;
    }
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { IdentifierNo, CashPrice, TradeUsage, KeyID, CashLastPayDate, EmailAddress, TradeType } = formData;
      // API 추가
      setLoadPanelVisible(true);
      await aggregation
        .issueCashBillInfo({
          IdentifierNo,
          TradeType,
          SalesPrice: CashPrice,
          TradeUsage,
          SalesKeyIDList: KeyID.join(';'),
          EmailAddress,
          TradeDateTime: moment(CashLastPayDate).format('YYYY-MM-DD HH:mm:ss'),
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '처리 되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            refresh();
            onClose();
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: res.error.detail,
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [formData],
  );

  const onClose = () => {
    formRef.current.instance.resetValues();
    setIsOpen(false);
    setFormData({
      TradeUsage: 'A',
      TradeType: 'A',
      KeyID: '',
      CashPrice: 0,
      UniqueID: '',
    });
  };

  const handleRadioValue = e => {
    const { IdentifierNo, CorpRegNumber } = rawdata;
    const identifierNo = e.value === 'A' ? IdentifierNo : CorpRegNumber;
    // 단일 선택
    if (CorpRegNumber) {
      setFormData({ ...formData, IdentifierNo: identifierNo });
    }
    // 복수 선택 or 단일 선택 사업자 번호 없음
    else {
      setFormData({ ...formData, IdentifierNo });
    }
  };

  const LabelTemplate = () => {
    return (
      <>
        <div>
          <i id="helpedInfo" className="dx-icon dx-icon-help" style={{ fontSize: '14px', paddingTop: '9px' }}></i>
        </div>
        <Tooltip target="#helpedInfo" showEvent="mouseenter" hideEvent="mouseleave">
          <div id="tooltip-content" style={{ textAlign: 'left', fontSize: '13px' }}>
            * 식별번호
            <br />
            소득공제용: 휴대폰 번호 or 자진발급번호
            <br />
            지출증빙용: 사업자번호 or 휴대폰번호
          </div>
        </Tooltip>
      </>
    );
  };

  const onSelfIssueClick = e => {
    const IdentifierNo = '010-000-1234';
    setFormData({ ...formData, IdentifierNo });
  };

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <div className="">
          <Form
            ref={formRef}
            className={'add-cashbill'}
            formData={formData}
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
            labelLocation="left"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption={'현금영수증 발행'}>
              <GroupItem colCount={15}>
                <SimpleItem
                  colSpan={15}
                  dataField="TradeUsage"
                  editorType="dxRadioGroup"
                  shouldRender="condition"
                  editorOptions={{
                    items: cashbillTypeRadioSelect,
                    valueExpr: 'value',
                    displayExpr: 'label',
                    layout: 'horizontal',
                    onValueChanged: handleRadioValue,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="거래구분" />
                </SimpleItem>
                <SimpleItem
                  colSpan={15}
                  dataField="TradeType"
                  editorType="dxSelectBox"
                  visible={false}
                  editorOptions={{
                    items: cashbillTradeTypeSelect,
                    displayExpr: 'text',
                    valueExpr: 'code',
                    // disabled: !isUpdatePrice,
                  }}
                >
                  <Label text="승인/취소" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>
                <SimpleItem
                  colSpan={14}
                  dataField="CashPrice"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    readOnly: true,
                  }}
                >
                  <Label text="금액" />
                </SimpleItem>
                <EmptyItem />
                <SimpleItem colSpan={14} dataField="EmailAddress" editorType="dxTextBox">
                  <EmailRule message="이메일 형식이 올바르지 않습니다." />
                  <Label text="E-mail" />
                </SimpleItem>
                <EmptyItem />
                <SimpleItem
                  colSpan={14}
                  dataField="IdentifierNo"
                  editorType="dxTextBox"
                  editorOptions={{
                    maxLength: 14, // 주민등록번호 입력시 '-' 포함 자릿수
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <PatternRule pattern={/^[\d-]+(\s+)?$/} message={'숫자와 - 기호만 입력 가능합니다.'} />
                  <Label text="식별번호" />
                </SimpleItem>
                <SimpleItem editorType="dxTextBox" render={LabelTemplate} />
              </GroupItem>

              <ButtonItem horizontalAlignment="left" cssClass="self-issue-btn">
                <ButtonOptions text="자진발급번호 적용" onClick={onSelfIssueClick} width={130} />
              </ButtonItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text={'저장'}></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
    </React.Fragment>
  );
});

export default AddCashbill;
