import React, { useState, useCallback, useImperativeHandle, useRef, useEffect, useMemo } from 'react';
import { Column, Pager, Selection, HeaderFilter, Scrolling, Summary, TotalItem } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { ticket, error } from 'api';
import notify from 'devextreme/ui/notify';
import CommonDataGrid from '../common/index';
import ExtendForm from '../components/extend-ticket-form';
import PaymentForm from 'common/popup/payment-form';
import PaymentDetailForm from 'common/popup/payment-detail-form';
import 'devextreme-react/text-area';
import '../season-ticket-mng.scss';
import { makePaysysStatusCellRender } from 'common/popup/ales-common-util';

const ConfirmTickets = React.forwardRef((props, ref) => {
  const { searchParam, refresh, ctdataSource, setLoadPanelVisible, gridDefault } = props;
  // 그리드 레프
  const ticketgridRef = useRef({});
  // 팝업 레프
  const extendRef = useRef({});
  // 결제 요청 레프
  const paymentRef = useRef({});
  // 결제 요청 상세보기 레프
  const paymentDetailRef = useRef({});

  // 전체 티켓, 선택된 티켓
  // const [tdataSource, setTDataSource] = useState([]);
  const [ticketID, setTicketID] = useState([]);
  // 코드 그룹

  const [selectMemberType, setSelectMemberType] = useState([]);
  const [selectPayType, setSelectPayType] = useState([]);
  const [selectPayStatus, setSelectPayStatus] = useState([]);

  // 팝업
  const [extendPopup, setExtendPopup] = useState({
    save: false,
    visible: false,
  });
  const [editPopup, setEditPopup] = useState({
    save: false,
    visible: false,
  });
  const [paymentPopup, setPaymentPopup] = useState({
    save: false,
    visible: false,
  });
  const [multEditPopup, setMultEditPopup] = useState({
    save: false,
    visible: false,
  });
  const [paymentDetailPopup, setPaymentDetailPopup] = useState({
    save: false,
    visible: false,
  });

  const [customizedColumns, setCustomizedColumns] = useState([]);

  useEffect(() => {
    const customizedCMS = localStorage.getItem('customizedSeasonTicket');
    if (customizedCMS) {
      const tmp_columns = JSON.parse(customizedCMS);
      setCustomizedColumns(tmp_columns);
    }
  }, []);
  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    ticketgridRef.current.instance.clearFilter();
    ticketgridRef.current.instance.clearSelection();
  }, [gridDefault]);
  useEffect(() => {
    (async () => {
      const result = await ticket.searchCodeMaster({
        CodeGroup: ['MemberType', 'PaymentType', 'PaymentStatus'],
      });
      const MemberType = [];
      const PaymentType = [];
      const PaymentStatus = [];
      if (result.isOk && result.data.length > 0) {
        result.data.forEach(a => {
          if (a.CodeGroup === 'MemberType') {
            MemberType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'PaymentType') {
            PaymentType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'PaymentStatus') {
            PaymentStatus.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          }
        });
      }
      setSelectMemberType(MemberType);
      setSelectPayType(PaymentType);
      setSelectPayStatus(PaymentStatus);
    })();
  }, []);

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsCount') options.totalValue = ticketID.length;
    else if (options.name === 'SelectedRowsSummary') {
      const selectedRows = options.component.getSelectedRowsData();
      const sum = selectedRows.reduce((a, c) => a + c.SalesPrice, 0);
      options.totalValue = sum;
    } else if (options.name === 'TotalCount') options.totalValue = ctdataSource.length;
  };

  // 정기권 발행 취소, 만료된 부분 cell style 변경
  const onCellPrepared = useCallback(({ data, cellElement }) => {
    if (data) {
      const { RemainDay } = data;
      const tr = cellElement.parentNode;
      if (RemainDay === '만료' && tr) {
        tr.classList.add('ticket-expiration');
      } else if (RemainDay.includes('취소') && tr) {
        tr.classList.add('ticket-cancel');
      }
    }
  }, []);

  // 정기권 연장
  const showExtendPopup = () => {
    if (ticketID.length === 0) {
      alert('연장할 정기권을 선택해 주세요');
    } else {
      setExtendPopup({ save: false, visible: !extendPopup.visible });
      extendRef.current.open(ticketID);
    }
  };

  // 정기권 발행 취소
  const onCancelClick = () => {
    const selectedTickets = ticketgridRef.current.instance.getSelectedRowsData();
    const payRequestTicket = selectedTickets.some(val => ['결제완료', '요청완료'].includes(val.PayRequestStatusName));
    if (ticketID.length === 0) {
      return alert('정기권을 선택해 주세요.');
    } else if (payRequestTicket) {
      return alert('결제 요청된 정기권은 취소할 수 없습니다.');
    }
    const result = confirm('<i>발행된 정기권을 취소하시겠습니까?</i>', '취소');
    result.then(dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);
        ticket
          .cancelSeasonTicketIssueHistory({
            TicketID: ticketID,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              // 발행 취소 완료
              setLoadPanelVisible(false);
              notify(
                {
                  message: '발행 취소가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  };

  const onSelectionChanged = e => {
    const selectRows = e.selectedRowsData;
    if (selectRows.length > 0) {
      const ticketIds = [];
      selectRows.forEach(r => {
        ticketIds.push(r.TicketID);
      });
      setTicketID(ticketIds);
    } else {
      setTicketID([]);
    }
  };

  const onClose = () => {
    setEditPopup({ ...editPopup, visible: false });
    setMultEditPopup({ ...multEditPopup, visible: false });
    setExtendPopup({ ...extendPopup, visible: false });
    setPaymentPopup({ ...paymentPopup, visible: false });
    setPaymentDetailPopup({ ...paymentDetailPopup, visible: false });
  };

  const onContentReady = e => {
    var columnChooserView = e.component.getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.on('hiding', () => {
        const rawData = columnChooserView.getColumns();
        const dataFieldsArr = rawData.map(column => column.dataField);
        localStorage.setItem('customizedSeasonTicket', JSON.stringify(dataFieldsArr));
      });
    }
  };

  const detailCellRender = e => {
    if (e.data) {
      const status = e.data.PayRequestStatusName;
      const isPaysys = status !== null && status !== '' && status !== undefined;
      return (
        <Button
          icon="search"
          width={30}
          visible={isPaysys}
          type="normal"
          stylingMode="text"
          onClick={() => {
            showPaymentDetailPopup(e.data);
          }}
        />
      );
    }
  };

  const payRequestStatusCellRender = useCallback(e => {
    return makePaysysStatusCellRender(e);
  }, []);

  const showPaymentPopup = () => {
    const selectedTickets = ticketgridRef.current.instance.getSelectedRowsData();
    if (selectedTickets.length === 0) {
      return alert('결제 요청할 매출을 선택해 주세요');
    } else if (ticketID.length > 1) {
      return alert('결제 요청은 일괄 처리할 수 없습니다. 1건만 선택 해주세요.');
    }
    const paymentType = selectedTickets[0].PaymentType;
    if (paymentType === 'TaxInvoice') {
      alert('세금계산서 매출은 결제 요청할 수 없습니다.');
    } else {
      (async () => {
        try {
          const result = await ticket.searchSeasonTicketIssueHistoryByID({
            TicketID: ticketID[0],
          });
          const status = result.data[0].SalesStatus;
          if (status === 'N') {
            setPaymentPopup({ save: false, visible: !paymentPopup.visible });
            paymentRef.current.open({ ...result.data[0], ...searchParam });
          } else alert('대사 전인 매출을 선택해주세요');
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      })();
    }
  };

  const showPaymentDetailPopup = data => {
    setPaymentDetailPopup({ save: false, visible: !paymentDetailPopup.visible });
    paymentDetailRef.current.open(data.UID);
  };

  return (
    <>
      <CommonDataGrid
        gridRef={ticketgridRef}
        width="100%"
        className={'dx-card wide-card ticket-history form-with-status'}
        dataSource={ctdataSource}
        keyExpr="TicketID"
        showBorders={true}
        columnAutoWidth={true}
        hoverStateEnabled={true}
        onContentReady={onContentReady}
        onSelectionChanged={onSelectionChanged}
        allowColumnResizing={true}
        height={701}
        onCellPrepared={onCellPrepared}
        allowColumnReordering={true}
        isExcel={true}
        isFilterSelection
        excelOptions={{
          sheetName: 'Sheet',
          fileName: '정기권 확정목록.xlsx',
        }}
        isHeader={true}
        headerAfter={[
          <Button
            icon="plus"
            text="연장"
            width={80}
            type="normal"
            stylingMode="contained"
            onClick={() => showExtendPopup()}
          />,
          <Button
            icon="minus"
            text="취소"
            width={80}
            type="danger"
            stylingMode="contained"
            onClick={() => onCancelClick()}
          />,
          <Button icon="fas fa-comments-dollar" text="결제 요청" mode="default" onClick={() => showPaymentPopup()} />,
          <Button
            icon="refresh"
            type="normal"
            stylingMode="text"
            onClick={() => {
              refresh();
            }}
          />,
        ]}
      >
        <HeaderFilter visible={true} />
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
        <Pager showPageSizeSelector={false} showInfo={false} visible={false} displayMode="full" />
        {/* <ColumnFixing enabled={true} /> */}
        <Column
          dataField={'VehicleRegistration'}
          width={100}
          caption={'차량번호'}
          fixed={true}
          // visible={!customizedColumns.includes('VehicleRegistration')}
        />
        <Column
          dataField={'FromDate'}
          caption={'시작일'}
          dataType="date"
          width={100}
          alignment={'left'}
          fixed={true}
          // visible={!customizedColumns.includes('FromDate')}
        />
        <Column
          dataField={'ToDate'}
          caption={'종료일'}
          dataType="date"
          width={100}
          alignment={'left'}
          fixed={true}
          // visible={!customizedColumns.includes('ToDate')}
        />
        <Column
          dataField={'SalesPrice'}
          caption={'판매금액'}
          width={100}
          alignment={'right'}
          format="#,##0 원"
          visible={!customizedColumns.includes('SalesPrice')}
        />
        {/* <Column dataField={'PaymentTypeName'} caption={'결제유형'} width={100} alignment={'left'} /> */}
        <Column
          dataField={'SalesStatusName'}
          caption={'매출상태'}
          width={100}
          alignment={'center'}
          visible={!customizedColumns.includes('SalesStatusName')}
        />
        <Column
          dataField={'AccountingSettlementNo'}
          caption={'현금영수증식별번호'}
          width={100}
          alignment={'center'}
          visible={!customizedColumns.includes('AccountingSettlementNo')}
        />
        <Column
          dataField={'PaymentCodeName'}
          caption={'수금구분'}
          width={100}
          alignment={'left'}
          visible={!customizedColumns.includes('PaymentCodeName')}
        />
        <Column
          dataField={'AccountingDate'}
          caption={'대사일'}
          width={100}
          dataType="date"
          alignment={'left'}
          visible={!customizedColumns.includes('AccountingDate')}
        />
        <Column
          dataField={'PaymentDate'}
          caption={'수금일'}
          dataType="date"
          width={100}
          alignment={'left'}
          visible={!customizedColumns.includes('PaymentDate')}
        />
        <Column
          dataField={'PayRequestStatusName'}
          caption={'결제요청상태'}
          width={120}
          alignment={'center'}
          cellRender={payRequestStatusCellRender}
          visible={!customizedColumns.includes('PayRequestStatusName')}
        />
        <Column
          dataField={'상세보기'}
          width={40}
          caption={'상세보기'}
          visible={!customizedColumns.includes('detail')}
          cssClass="cell-remove-padding"
          cellRender={detailCellRender}
        />
        <Column
          dataField={'PayExpireDate'}
          caption={'결제만료일'}
          width={120}
          alignment={'left'}
          visible={!customizedColumns.includes('PayExpireDate')}
        />
        <Column
          dataField={'MemberName'}
          caption={'회원명'}
          width={80}
          alignment={'left'}
          visible={!customizedColumns.includes('MemberName')}
        />
        {/* <Column dataField={'GroupCode'} width={80} visible={false} /> */}
        <Column
          dataField={'GroupName'}
          caption={'회원그룹'}
          width={100}
          alignment={'left'}
          visible={!customizedColumns.includes('GroupName')}
        />
        <Column
          dataField={'CustomerCorpName'}
          caption={'사업자명'}
          width={150}
          visible={!customizedColumns.includes('CustomerCorpName')}
        />
        <Column
          dataField={'ExtendStatusName'}
          caption={'상태'}
          width={100}
          visible={!customizedColumns.includes('ExtendStatusName')}
        />
        <Column
          dataField={'RemainDay'}
          caption={'남은일수'}
          width={100}
          alignmen
          t={'left'}
          visible={!customizedColumns.includes('RemainDay')}
        />
        {/* <Column dataField={'PaymentStatusName'} caption={'결제상태'} width={100} alignment={'left'} /> */}
        <Column
          dataField={'MemberTypeName'}
          caption={'정기권유형'}
          width={110}
          alignment={'left'}
          visible={!customizedColumns.includes('MemberTypeName')}
        />
        <Column
          dataField={'MemberDivisionName'}
          caption={'회원구분'}
          width={110}
          alignment={'left'}
          visible={!customizedColumns.includes('MemberDivisionName')}
        />
        <Column
          dataField={'SalesDate'}
          caption={'판매일'}
          dataType="date"
          width={110}
          alignment={'left'}
          visible={!customizedColumns.includes('SalesDate')}
        />
        <Column
          dataField={'Remark'}
          caption={'비고'}
          width={80}
          alignment={'left'}
          visible={!customizedColumns.includes('Remark')}
        />
        <Column
          dataField={'RegisterDate'}
          caption={'등록일시'}
          width={100}
          dataType="date"
          alignment={'left'}
          visible={!customizedColumns.includes('RegisterDate')}
          // customizeText={customizeText}
        />
        <Column
          dataField={'CreditCardApprovalNo'}
          caption={'승인번호'}
          width={100}
          alignmen
          t={'left'}
          visible={!customizedColumns.includes('CreditCardApprovalNo')}
        />
        <Column
          dataField={'CreditCardNo'}
          caption={'카드번호'}
          width={100}
          alignmen
          t={'left'}
          visible={!customizedColumns.includes('CreditCardNo')}
        />
        <Column
          dataField={'IssueCreditCard'}
          caption={'발급사'}
          width={100}
          alignmen
          t={'left'}
          visible={!customizedColumns.includes('IssueCreditCard')}
        />
        <Column
          dataField={'SettleCreditCard'}
          caption={'매입사'}
          width={100}
          alignmen
          t={'left'}
          visible={!customizedColumns.includes('SettleCreditCard')}
        />
        <Column
          dataField={'RegisterUser'}
          caption={'등록자'}
          alignment={'left'}
          visible={!customizedColumns.includes('RegisterUser')}
        />
        {/* <Column dataField={'TicketID'} visible={false} /> */}
        <Summary calculateCustomSummary={calculateSelectedRow}>
          <TotalItem
            name="SelectedRowsCount"
            summaryType="custom"
            displayFormat="선택 {0}건"
            showInColumn="VehicleRegistration"
          />
          <TotalItem showInColumn="FromDate" valueFormat="선택합계:" />
          <TotalItem
            name="SelectedRowsSummary"
            summaryType="custom"
            showInColumn="FromDate"
            displayFormat="{0}"
            valueFormat="#,##0 원"
          />
          <TotalItem name="TotalCount" summaryType="custom" showInColumn="ToDate" displayFormat="총 {0}건" />
          <TotalItem showInColumn="SalesPrice" valueFormat="전체합계:" />
          <TotalItem
            column="SalesPrice"
            summaryType="sum"
            displayFormat="{0}"
            showInColumn="SalesPrice"
            valueFormat="#,##0 원"
          />
        </Summary>
      </CommonDataGrid>
      {/* 정기권 연장 팝업 */}
      <Popup onHiding={onClose} visible={extendPopup.visible} showTitle={false} width="25%" height={360}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <ExtendForm
            ref={extendRef}
            setPopup={setExtendPopup}
            refresh={refresh}
            // selectPayType={selectPayType}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>
      {/* 정기권 결제 요청 팝업 */}
      <Popup onHiding={onClose} visible={paymentPopup.visible} showTitle={false} width="35%" height={700}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <PaymentForm
            ref={paymentRef}
            setPopup={setPaymentPopup}
            refresh={refresh}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>
      {/* 정기권 결제 요청 상세보기 팝업 */}
      <Popup onHiding={onClose} visible={paymentDetailPopup.visible} showTitle={false} width="40%" height={800}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <PaymentDetailForm
            ref={paymentDetailRef}
            setPopup={setPaymentDetailPopup}
            refresh={refresh}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>
    </>
  );
});

export default React.memo(ConfirmTickets);
