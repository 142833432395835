import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import CommonDataGrid from 'common/default-data-grid';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';
import { makeTaxInvoiceStatusCellRender, notifyS } from 'common/popup/sales-common-util';
import { TagBox } from 'devextreme-react/tag-box';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { common, error } from 'api';
import notify from 'devextreme/ui/notify';
import DateBox from 'devextreme-react/date-box';
import jQuery from 'jquery';
import $ from 'jquery';
import 'styles/CommonStyles.scss';

const userId = localStorage.getItem('userId');

const UnpayedTaxInvoiceReport = () => {
  const maingridRef = useRef({});
  const subgridRef = useRef({});
  //검색
  const [dataSourceTax, setdataSourceTax] = useState({});
  const [dataSourceSales, setdataSourceSales] = useState({});
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    StandardDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  // 조회할 사업소 목록 array
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [seasonticktOnleyCheck, setSeasonticktOnleyCheck] = useState('N');
  // const [byPaymentDate, setPaymentDateCheck] = useState('N');

  const taxInvoiceStatusCellRender = useCallback(e => {
    return makeTaxInvoiceStatusCellRender(e);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onClickButton = async () => {
    try {
      setLoadPanelVisible(true);
      const result = await axios.post(`aggregation/history/getUnpayedTaxInvoiceReport`, {
        ...dateValue,
        CostCenterCode: '*',
        LoginID: userId,
        onlySeasonTicket: seasonticktOnleyCheck,
      });
      setdataSourceTax(result.data[0]);
      setdataSourceSales(result.data[1]);
      setLoadPanelVisible(false);
    } catch (e) {
      setLoadPanelVisible(false);
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  };

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };
  const handleStandardDate = e => {
    setDateValue({ ...dateValue, StandardDate: e.value });
  };

  const onSeasonTicketCheckValueChanged = e => {
    e.value ? setSeasonticktOnleyCheck('Y') : setSeasonticktOnleyCheck('N');
  };

  const onCellPreparedInvoice = useCallback(e => {
    cellStatusInvoice(e);
  }, []);

  var mergelements = {};
  const mergeStandardColumn = 'InvoiceID';
  const mergeColumn = [
    'TaxInvoiceStatusName',
    'TaxIssueTypeName',
    'InvoiceID',
    'TaxSalesPrice',
    'TaxInvoiceNo',
    'TaxSaleDate',
    'TaxCustomerNo',
    'TaxCorpRegNumber',
    'TaxCustomerCorpName',
    'TaxCostCenterName',
    'TaxHiParkingID',
  ];

  const cellStatusInvoice = e => {
    if (e.data) {
      // const { InvoiceID } = e.data;
      if (e.rowType === 'data' && e.rowIndex > 0 && mergeColumn.includes(e.column.dataField)) {
        // cellElement.addClass('mergecells');
        // cellElement.click(function () {
        //   '.mergecellselected'.removeClass('mergecellselected')(this).addClass('mergecellselected');
        // });
        if (
          e.component.cellValue(e.rowIndex - 1, mergeStandardColumn) ===
          e.component.cellValue(e.rowIndex, mergeStandardColumn)
        ) {
          if (e.component.cellValue(e.rowIndex - 1, e.column.dataField)) {
            var prev = mergelements[e.rowIndex - 1][e.column.dataField];
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = prev;
            if (prev) {
              $(e.cellElement).css('display', 'none');
              var span = $(prev).attr('rowspan');
              if (span) $(prev).attr('rowspan', Number(span) + 1);
              else $(prev).attr('rowspan', 2);
            }
          } else {
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
          }
        } else {
          if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
          mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
        }
      } else {
        if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
        mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
      }
    }
  };
  return (
    <div>
      <h2 className={'content-block detail-title'}>미처리 채권 현황 (기준일 시점)</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <div className="label">매출일 :</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <span> ~ </span>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <div className="label">기준일 :</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="StandardDate"
                onValueChanged={handleStandardDate}
                value={dateValue.StandardDate}
              />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button
                icon="search"
                text="조회"
                width={120}
                type="normal"
                stylingMode="contained"
                onClick={onClickButton}
              />
            </ToolbarItem>
          </Toolbar>
          <div style={{ paddingTop: '5px' }}>
            <TabPanel deferRendering={false} height={675}>
              <Item title="세금계산서" name="background">
                <CommonDataGrid
                  gridRef={maingridRef}
                  className={'dx-card wide-card grid-with-status'}
                  dataSource={dataSourceTax}
                  showBorders={true}
                  columnAutoWidth={true}
                  allowColumnResizing={true}
                  columnHidingEnabled={false}
                  hoverStateEnabled={true}
                  height={600}
                  isHeader={true}
                  isExcel={true}
                  excelOptions={{
                    sheetName: 'sheet',
                    fileName: '미처리 세금계산서 현황.xlsx',
                  }}
                >
                  <Scrolling mode="virtual" rowRenderingMode="virtual" />
                  <Column dataField={'HiParkingID'} caption={'P코드'} width={'70'} />
                  <Column dataField={'CostCenterName'} caption={'사업소명'} width={120} allowEditing={false} />
                  <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} allowEditing={false} />
                  <Column
                    dataField={'TaxInvoiceStatusName'}
                    caption={'처리상태'}
                    width={110}
                    cellRender={taxInvoiceStatusCellRender}
                    allowEditing={false}
                  />
                  <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={110} allowEditing={false} />
                  <Column dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} allowEditing={true} />
                  <Column dataField={'StatusName'} caption={'매출상태'} width={80} allowEditing={false} />
                  <Column dataField={'AccountingDate'} caption={'대사일'} width={90} allowEditing={false} />
                  <Column dataField={'SalesPrice'} caption={'발행금액'} width={160} format="#,##0 원" />
                  <Column dataField={'PaymentDate'} caption={'마지막결제일'} format="#,##0 원" width={'120'} />
                  <Column dataField={'PointRemainAmount'} caption={'시점기준잔액'} format="#,##0 원" width={'120'} />
                  <Column dataField={'PointPaymentAmount'} caption={'시점기준수금액'} format="#,##0 원" width={'120'} />
                  <Column dataField={'RemainAmount'} caption={'현잔액'} format="#,##0 원" width={'104'} />
                  <Column dataField={'CustomerName'} caption={'사업자명'} width={200} allowEditing={false} />
                  <Column dataField={'CustomerCorpRegNumber'} caption={'사업자번호'} width={120} allowEditing={false} />
                  <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} allowEditing={false} />
                  <Column dataField={'TaxTypeName'} caption={'과세유형'} width={80} allowEditing={false} />
                  <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} allowEditing={false} />
                  <Column dataField={'SalesCount'} caption={'품목 수'} width={80} allowEditing={false} />
                  <Column dataField={'TaxPrice'} caption={'부가세'} width={80} allowEditing={false} />
                  <Column dataField={'UnitPrice'} caption={'공급가액'} width={80} allowEditing={false} />
                  <Column dataField={'Remark'} caption={'적요'} width={200} />
                  <Column dataField={'CustomerCode'} caption={'회사코드'} width={120} allowEditing={false} />
                  <Column
                    dataField={'CustomerManagerName'}
                    caption={'매입처 담당자명'}
                    width={100}
                    allowEditing={false}
                  />
                  <Column dataField={'CustomerEmail1'} caption={'이메일'} width={120} allowEditing={false} />
                  <Column dataField={'CustomerEmail2'} caption={'이메일2'} width={120} allowEditing={false} />
                  <Column dataField={'RegisterUser'} caption={'최초등록자'} width={80} allowEditing={false} />
                  <Column
                    dataField={'RegisterDate'}
                    caption={'최초등록일'}
                    width={120}
                    cellRender={customizeText}
                    allowEditing={false}
                  />
                  <Column
                    dataField={'LastUpdateDate'}
                    caption={'마지막수정일'}
                    width={120}
                    cellRender={customizeText}
                    allowEditing={false}
                  />
                  <Summary>
                    <TotalItem
                      column="PointRemainAmount"
                      summaryType="sum"
                      valueFormat="#,##0 원"
                      displayFormat="합계: {0}"
                    />
                    <TotalItem
                      column="PointPaymentAmount"
                      summaryType="sum"
                      valueFormat="#,##0 원"
                      displayFormat="합계: {0}"
                    />
                    <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
                    <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                  </Summary>
                </CommonDataGrid>
              </Item>
              <Item title="매출(정기권/기타)" name="background">
                <CommonDataGrid
                  gridRef={subgridRef}
                  className={'dx-card wide-card grid-with-status'}
                  dataSource={dataSourceSales}
                  showBorders={true}
                  columnAutoWidth={true}
                  allowColumnResizing={true}
                  columnHidingEnabled={false}
                  hoverStateEnabled={true}
                  height={600}
                  isHeader={true}
                  isExcel={true}
                  excelOptions={{
                    sheetName: 'sheet',
                    fileName: '미처리 매출 채권 현황.xlsx',
                  }}
                >
                  <Scrolling mode="virtual" rowRenderingMode="virtual" />
                  <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
                  <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
                  <Column dataField={'SalesDate'} caption={'매출일'} alignment={'center'} />
                  <Column dataField={'SalesDateOrigin'} caption={'원매출일'} alignment={'center'} />
                  <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
                  <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} />

                  <Column dataField={'StatusName'} caption={'매출상태'} width={80} allowEditing={false} />
                  <Column dataField={'AccountingDate'} caption={'대사일'} width={90} allowEditing={false} />
                  <Column dataField={'SalesPrice'} caption={'매출금액'} width={160} format="#,##0 원" />
                  <Column dataField={'PaymentDate'} caption={'마지막결제일'} format="#,##0 원" width={'120'} />
                  <Column dataField={'PointRemainAmount'} caption={'시점기준잔액'} format="#,##0 원" width={'120'} />
                  <Column dataField={'PointPaymentAmount'} caption={'시점기준수금액'} format="#,##0 원" width={'120'} />
                  <Column dataField={'RemainAmount'} caption={'현잔액'} format="#,##0 원" width={'104'} />
                  <Column dataField={'FromDate'} caption={'매출대상기간From'} alignment={'center'} />
                  <Column dataField={'ToDate'} caption={'매출대상기간To'} alignment={'center'} />
                  <Column dataField={'VehicleRegistrationNo'} caption={'차량/고객'} alignment={'left'} />
                  <Column dataField={'CustomerNo'} caption={'SAP사업자코드'} alignment={'left'} />
                  <Column dataField={'CorpRegNumber'} caption={'사업자번호'} alignment={'left'} />
                  <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={200} allowEditing={false} />
                  <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
                  <Column dataField={'PaymentAgency'} caption={'결제사'} alignment={'center'} />
                  <Column dataField={'AccountingNo'} caption={'대사ID'} alignment={'center'} />
                  <Column dataField={'CustomerCorpRegNumber'} caption={'사업자번호'} width={120} allowEditing={false} />
                  <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} allowEditing={false} />
                  <Column dataField={'RegisterUser'} caption={'최초등록자'} width={80} allowEditing={false} />
                  <Column
                    dataField={'RegisterDate'}
                    caption={'등록일'}
                    width={120}
                    cellRender={customizeText}
                    allowEditing={false}
                  />
                  <Summary>
                    <TotalItem
                      column="PointRemainAmount"
                      summaryType="sum"
                      valueFormat="#,##0 원"
                      displayFormat="합계: {0}"
                    />
                    <TotalItem
                      column="PointPaymentAmount"
                      summaryType="sum"
                      valueFormat="#,##0 원"
                      displayFormat="합계: {0}"
                    />
                    <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
                    <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                  </Summary>
                </CommonDataGrid>
              </Item>
            </TabPanel>
          </div>
          <PSLoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '.content' }} visible={loadPanelVisible} />
        </div>
      </div>

      <PSLoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '.dx-popup-wrapper' }} visible={loadPanelVisible} />
    </div>
  );
};

export default UnpayedTaxInvoiceReport;
