import React, { useMemo, useRef } from 'react';
import { Column, Scrolling, HeaderFilter, TotalItem, Summary } from 'devextreme-react/data-grid';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { aggregation } from 'api';
import CommonDataGrid from 'common/default-data-grid';

const EtcSales = props => {
  const { searchData } = props;
  const gridRef = useRef({});
  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        if (searchData.FromDate !== null && searchData.FromDate !== '') {
          const result = await aggregation.getOtherTicketSalesDataByDay({ ...searchData });
          return result.data;
        } else {
          return [];
        }
      },
    });
    return customDataSource;
  }, [searchData]);

  const customizeText = cellInfo => {
    const res = cellInfo.value && moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
    return res;
  };

  return (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={600}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '전체매출조회 기타매출.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <HeaderFilter visible={true} />
        <Column dataField={'HiParkingID'} caption={'P코드'} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} />
        <Column dataField={'AccountingDate'} caption={'대사날짜'} />
        <Column dataField={'EtcSalesTypeName'} caption={'고객유형'} />
        <Column dataField={'CustomerName'} caption={'고객명'} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" />
        <Column dataField={'SalesDate'} caption={'매출일'} customizeText={customizeText} />
        <Column dataField={'PaymentTypeName'} caption={'결제유형'} />
        <Column dataField={'PaymentDate'} caption={'결제시간'} customizeText={customizeText} />
        <Column dataField={'PaymentAgency'} caption={'결제사'} />
        <Column dataField={'PaymentCode'} caption={'수금구분'} />
        <Column dataField={'StatusName'} caption={'매출상태'} />
        <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
        <Column dataField={'SettlementNo'} caption={'세금계산서번호'} />
        <Column dataField={'CorpRegNumber'} caption={'사업자번호'} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} />
        <Column dataField={'Remark'} caption={'비고'} />
        <Column dataField={'RegisterDate'} caption={'등록일'} customizeText={customizeText} />
        <Column dataField={'AccountingNo'} caption={'대사ID'} />
        <Summary>
          <TotalItem column="CostCenterName" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(EtcSales);
