import React, { useCallback, useState, useImperativeHandle, useEffect, useRef } from 'react';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from 'devextreme-react/form';
import { ticket, error } from 'api';
import { useAuth } from '../../contexts/auth';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

const PaymentForm = React.forwardRef((props, ref) => {
  const { user } = useAuth();
  const { setPopup, refresh, setLoadPanelVisible } = props;
  const [formData, setFormData] = useState({ FromDate: null, ToDate: null });
  const [useFixedAccount, setUseFixedAccount] = useState(false);
  const [bankType, setBankType] = useState({});
  const now = new Date();
  // const [paysysPaymentType, setPaysysPaymentType] = useState({});
  const paysysPaymentType = [
    {
      label: '신용카드',
      value: '0',
    },
    {
      label: '일회가상계좌',
      value: '1',
    },
    {
      label: '고정가상계좌',
      value: '2',
    },
  ];
  useImperativeHandle(ref, () => ({
    open,
  }));

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCodeMaster({
        CodeGroup: ['BankType', 'PaysysPaymentType'],
      });
      const BankType = [];
      const PaysysPaymentType = [];
      if (result.isOk && result.data.length > 0) {
        result.data.forEach(a => {
          if (a.CodeGroup === 'BankType') {
            BankType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'PaysysPaymentType') {
            PaysysPaymentType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          }
        });
        setBankType(BankType);
        // setPaysysPaymentType(PaysysPaymentType);
      }
    })();
  }, []);

  const getGoodsType = data => {
    const etcType = data.EtcSalesType;
    if (!etcType) {
      return '0';
    } else if (etcType === '011') {
      return '1';
    } else if (etcType === '004' || etcType === '009') {
      return '2';
    } else if (etcType) {
      return '3';
    }
  };

  const getGoodsName = data => {
    const { EtcSalesTypeName, VehicleRegistration, FromDate, ToDate, SalesDate } = data;
    const etcType = data.EtcSalesType;
    if (!etcType) {
      const str = '정기권' + ' ' + VehicleRegistration + ' ' + '(' + FromDate + '~' + ToDate + ')';
      return str.substring(0, 40);
    } else if (etcType) {
      const str = EtcSalesTypeName + ' ' + '(' + SalesDate + ')';
      return str.substring(0, 40);
    }
  };

  const open = selectedData => {
    const GoodsType = getGoodsType(selectedData);
    const GoodsName = getGoodsName(selectedData);
    const defaultFixAccount = { FixAccountBankCode: null, FixAccountStartDate: null, FixAccountEndDate: null };
    setFormData({
      ...selectedData,
      ...defaultFixAccount,
      GoodsType,
      GoodsName,
    });
  };
  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const registerUserMail = user.Email.split('@')[0];
      const {
        TicketID,
        CostCenterCode,
        MemberName, // CustomerName
        MobilePhoneNo,
        EmailAddress,
        PayType,
        GoodsType,
        GoodsName,
        VehicleRegistration,
        FromDate,
        ToDate,
        UnitPrice,
        Quantity, // Unit
        SalesPrice, // TotalPrice
        FixAccountBankCode,
        FixAccountStartDate,
        FixAccountEndDate,
        LoginID,
      } = formData;
      const formattedFromDate =
        GoodsType === '1' ? FromDate && moment(FromDate).format('YYYY-MM-DD HH:mm:ss') : FromDate;
      const formattedToDate = GoodsType === '1' ? ToDate && moment(ToDate).format('YYYY-MM-DD HH:mm:ss') : ToDate;
      const params = {
        TicketID,
        RegisterUserMail: registerUserMail,
        CostCenterCode,
        CustomerName: MemberName,
        MobilePhoneNo,
        EmailAddress,
        PayType,
        GoodsType,
        GoodsName,
        VehicleRegistrationNo: VehicleRegistration,
        FromDate: formattedFromDate || '',
        ToDate: formattedToDate || '',
        UnitPrice,
        Unit: Quantity,
        TotalPrice: SalesPrice,
        FixAccountBankCode,
        FixAccountStartDate: FixAccountStartDate && moment(FixAccountStartDate).format('YYYY-MM-DD'),
        FixAccountEndDate: FixAccountEndDate && moment(FixAccountEndDate).format('YYYY-MM-DD'),
        LoginID,
      };
      setLoadPanelVisible(true);
      await ticket.registerPayRequestInfo(params).then(res => {
        if (res.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '결제 요청 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          refresh();
          onClose();
        } else {
          // error
          setLoadPanelVisible(false);
          notify(
            {
              message: res.error.detail,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      });
    },
    [formData],
  );

  const onClose = () => {
    setPopup({ save: false, visible: false });
    setUseFixedAccount(false);
    setFormData({ FromDate: null, ToDate: null });
  };

  const handlePaymentDateFrom = e => {
    if (e.value) {
      setFormData({
        ...formData,
      });
    }
  };

  const onItemClick = e => {
    const value = e.itemData.value;
    if (value === '2') {
      setUseFixedAccount(true);
    } else {
      setUseFixedAccount(false);
      setFormData({ ...formData, FixAccountBankCode: null, FixAccountEndDate: null, FixAccountStartDate: null });
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          formData={formData}
          labelLocation="top"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
          height={650}
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="통합결제시스템 결제 요청" colCount={2}>
            <SimpleItem
              dataField="GoodsName"
              editorType="dxTextBox"
              colSpan={2}
              editorOptions={{ readOnly: false, maxLength: 40 }}
            >
              <Label text="상품명" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="CostCenterName"
              editorType="dxTextBox"
              colSpan={1}
              editorOptions={{ readOnly: true }}
            >
              <Label text="사업소" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="MemberName"
              editorType="dxTextBox"
              colSpan={1}
              editorOptions={{ readOnly: false, maxLength: 32 }}
            >
              <Label text="회원명" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="VehicleRegistration"
              visible={formData.GoodsType === '0' || formData.GoodsType === '1'}
              editorType="dxTextBox"
              colSpan={1}
              editorOptions={{ readOnly: formData.GoodsType === '0', maxLength: 16 }}
            >
              <Label text="차량번호" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="Quantity"
              // 지류할인권, 웹할인권
              visible={formData.GoodsType === '2'}
              editorType="dxTextBox"
              colSpan={1}
              editorOptions={{ readOnly: true }}
            >
              <Label text="수량" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="UnitPrice"
              // 지류할인권, 웹할인권
              visible={formData.GoodsType === '2'}
              editorType="dxNumberBox"
              colSpan={1}
              editorOptions={{ readOnly: true, format: '#,##0 원' }}
            >
              <Label text="단가" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="SalesPrice"
              editorType="dxNumberBox"
              colSpan={1}
              editorOptions={{ readOnly: true, format: '#,##0 원' }}
            >
              <Label text="금액" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            {formData.GoodsType === '0' ? (
              <SimpleItem dataField="FromDate" visible={true}>
                <DateBox
                  type="date"
                  stylingMode="outlined"
                  displayFormat="yyyy-MM-dd"
                  readOnly={true}
                  value={formData.FromDate}
                />
                <Label text="정기권 시작일" location="top" />
              </SimpleItem>
            ) : (
              <SimpleItem
                dataField="FromDate"
                editorType="dxDateBox"
                colSpan={1}
                visible={formData.GoodsType === '1'}
                editorOptions={{
                  type: 'datetime',
                  dropDownButtonTemplate: 'dropDownButton',
                  displayFormat: 'yyyy-MM-dd HH:mm:ss',
                  useMaskBehavior: true,
                  showAnalogClock: false,
                  icon: true,
                  // onValueChanged: handleFromValueChanged,
                }}
              >
                <Label text="입차일시" location="top" />
              </SimpleItem>
            )}
            {formData.GoodsType === '0' ? (
              <SimpleItem dataField="ToDate" visible={true}>
                <DateBox
                  type="date"
                  stylingMode="outlined"
                  displayFormat="yyyy-MM-dd"
                  readOnly={true}
                  value={formData.ToDate}
                />
                <Label text="정기권 종료일" location="top" />
              </SimpleItem>
            ) : (
              <SimpleItem
                dataField="ToDate"
                editorType="dxDateBox"
                visible={formData.GoodsType === '1'}
                colSpan={1}
                editorOptions={{
                  type: 'datetime',
                  dropDownButtonTemplate: 'dropDownButton',
                  displayFormat: 'yyyy-MM-dd HH:mm:ss',
                  useMaskBehavior: true,
                  icon: true,
                  showAnalogClock: false,
                  min: formData.FromDate,
                  dateOutOfRangeMessage: '입차 일시보다 빠를 수 없습니다.',
                }}
              >
                <Label text="출차일시" location="top" />
              </SimpleItem>
            )}
            <SimpleItem dataField="MobilePhoneNo" editorType="dxTextBox" colSpan={1} editorOptions={{ maxLength: 14 }}>
              <Label text="결제링크 수신 전화번호" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem dataField="EmailAddress" editorType="dxTextBox" colSpan={1}>
              <EmailRule message="이메일 형식이 올바르지 않습니다." />
              <Label text="결제링크 수신 email" location="top" />
            </SimpleItem>
            <SimpleItem
              dataField="PayType"
              editorType="dxSelectBox"
              colSpan={1}
              editorOptions={{
                onItemClick: onItemClick,
                items: paysysPaymentType,
                valueExpr: 'value',
                displayExpr: 'label',
                layout: 'horizontal',
              }}
            >
              <Label text="결제방식" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            {useFixedAccount && (
              <SimpleItem
                dataField="FixAccountBankCode"
                editorType="dxSelectBox"
                colSpan={1}
                editorOptions={{ items: bankType, valueExpr: 'code', displayExpr: 'codeDesc' }}
              >
                <Label text="은행코드" location="top" />
                <RequiredRule message="필수값 입니다." />
              </SimpleItem>
            )}
            {useFixedAccount && (
              <SimpleItem
                dataField="FixAccountStartDate"
                editorType="dxDateBox"
                colSpan={1}
                editorOptions={{
                  type: 'date',
                  dropDownButtonTemplate: 'dropDownButton',
                  displayFormat: 'yyyy-MM-dd',
                  useMaskBehavior: true,
                  min: now,
                  icon: true,
                  onValueChanged: handlePaymentDateFrom,
                }}
              >
                <Label text="결제시작일" location="top" />
                <RequiredRule message="필수값 입니다." />
              </SimpleItem>
            )}
            {useFixedAccount && (
              <SimpleItem
                dataField="FixAccountEndDate"
                editorType="dxDateBox"
                colSpan={1}
                editorOptions={{
                  type: 'date',
                  dropDownButtonTemplate: 'dropDownButton',
                  displayFormat: 'yyyy-MM-dd',
                  useMaskBehavior: true,
                  icon: true,
                  min: now && formData.FixAccountStartDate,
                  dateOutOfRangeMessage: '결제 시작일보다 빠를 수 없습니다.',
                }}
              >
                <Label text="결제마지막일" location="top" />
                <RequiredRule message="필수값 입니다." />
              </SimpleItem>
            )}
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions
                colSpan={1}
                width={'50%'}
                type="default"
                text="결제 요청"
                useSubmitBehavior={true}
              ></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="top">
              <ButtonOptions colSpan={1} width={'50%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default PaymentForm;
