import { exportDataGrid } from 'devextreme/excel_exporter';

export async function createWorksheet2(worksheet, searchData, gridRef, gridRef2) {
  const { CostCenterName } = searchData;
  const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
  const alignmentLeft = { vertical: 'middle', horizontal: 'left' };

  const startRowIdx = 3;
  const startColIdx = 1;

  const exporGridResult = exportDataGrid({
    component: gridRef.current.instance,
    worksheet: worksheet,
    topLeftCell: { row: startRowIdx, column: startColIdx },
    customizeCell: ({ gridCell, excelCell }) => {
      if (gridCell.column && gridCell.rowType === 'data' && gridCell.column.index === 5) {
        excelCell.numFmt = '#,##0';
      }
      if (gridCell) {
        excelCell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
      }
      if ((gridCell.rowType && gridCell.rowType === 'totalFooter') || gridCell.rowType === 'header') {
        excelCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ddebf7' },
        };
        excelCell.font = { bold: true };
      }
    },
    autoFilterEnabled: true,
  });
  await exporGridResult;

  const exporSumGridResult = exportDataGrid({
    component: gridRef2.current.instance,
    worksheet: worksheet,
    topLeftCell: { row: startRowIdx, column: 9 },
    customizeCell: ({ gridCell, excelCell }) => {
      if (gridCell.column && gridCell.rowType === 'data' && gridCell.column.index === 1) {
        excelCell.numFmt = '#,##0';
      }
      if ((gridCell.rowType && gridCell.rowType === 'totalFooter') || gridCell.rowType === 'header') {
        excelCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ddebf7' },
        };
        excelCell.font = { bold: true };
      }
      if (gridCell) {
        excelCell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
      }
    },
    autoFilterEnabled: true,
  });
  await exporSumGridResult;

  worksheet.columns = [
    { width: 15 },
    { width: 15 },
    { width: 18 },
    { width: 12 },
    { width: 12 },
    { width: 15 },
    { width: 10 },
    { width: 10 },
    { width: 15 },
    { width: 15 },
  ];
  worksheet.getCell('A1').value = {
    richText: [{ text: `■ ${CostCenterName} 매출 내역`, font: { bold: true } }],
  };
  worksheet.pageSetup.scale = 54;

  worksheet.getColumn('A').alignment = alignmentCenter;
  worksheet.getColumn('D').alignment = alignmentCenter;
  worksheet.getColumn('E').alignment = alignmentCenter;
  worksheet.getColumn('I').alignment = alignmentCenter;
  worksheet.getCell('B3').alignment = alignmentCenter;
  worksheet.getCell('C3').alignment = alignmentCenter;
  worksheet.getCell('F3').alignment = alignmentCenter;
  worksheet.getCell('G3').alignment = alignmentCenter;
  worksheet.getCell('A1').alignment = alignmentLeft;
  worksheet.getCell('I3').value = '항목구분';
  worksheet.getCell('J3').value = '금액';
}
