import React, { useEffect, useCallback, useRef, useState, useImperativeHandle } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { salesPayment, error } from 'api';
import { isWithin3Months } from 'common/validators/validators';
import CustomInfoPopup from '../../sales-taxinvoice/popup/CustomInfoPopup';
import CostcenterMaster from '../../../../common/popup/costcenter-master';

const AddSalesMaster = React.forwardRef((props, ref) => {
  const formRef = useRef({});
  const customInfoRef = useRef({});
  const costcenterMasterRef = useRef({});
  const {
    setIsOpen,
    etcType,
    customerType,
    paymentType,
    CostCenterCode,
    CostCenterName,
    refresh,
    isClosePending,
    isEtcSale,
    setLoadPanelVisible,
  } = props;
  const [formData, setFormData] = useState({
    EtcSalesType: '',
    CustomerType: '',
    CustomerName: '',
    CustomerCode: '',
    PaymentType: '',
    FromDate: '',
    ToDate: '',
    Quantity: '',
    UnitPrice: '',
    SalesPrice: 0,
    SalesDate: '',
    IsTaxFree: false,
    Remark: '',
  });
  const [ticketID, setTicketID] = useState(null);
  const [addParams, setAddParams] = useState({
    SalesType: '',
    KeyIDList: [],
    CustomerCorpRegNumber: '',
    CustomerCorpName: '',
    NameOfRepresentative: '',
    CustomerCode: '',
  });
  const [isGarageSales, setIsGarageSales] = useState(false);
  const [useUnitPrice, setUseUnitPrice] = useState(false);
  const [useSalesItemName, setUseSalesItemName] = useState(false);
  const [useTaxFree, setUseTaxFree] = useState(false);
  const [dateValue, setDateValue] = useState('');

  // 사업자 선택
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);

  // 사업소 선택
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = (TicketID, data) => {
    //id값이 있으면 update
    if (TicketID) {
      salesPayment.searchOtherTicketIssueHistoryByID({ TicketID }).then(res => {
        if (res.isOk) {
          const { CustomerCorpRegNumber, CustomerCorpName, NameOfRepresentative, CustomerCode } = res.data[0];
          setFormData(res.data[0]);
          setAddParams({
            CustomerCorpRegNumber,
            CustomerCorpName,
            NameOfRepresentative,
            CustomerCode,
          });
          setTicketID(TicketID);
        }
      });
    } else if (data) {
      const keyIDList = data.map(d => d.KeyID);
      const sales = data.reduce((a, c) => {
        return a + c.SalesPrice;
      }, 0);
      setFormData({
        EtcSalesType: '',
        CustomerType: '',
        CustomerName: '',
        CustomerCode: '',
        PaymentType: '',
        FromDate: '',
        ToDate: '',
        Quantity: '',
        UnitPrice: '',
        IsTaxFree: false,
        SalesPrice: sales,
        SalesDate: '',
        Remark: '',
      });
      setAddParams({
        SalesType: data[0].SalesType,
        KeyIDList: keyIDList.join(';'),
        CustomerCorpRegNumber: '',
        CustomerCorpName: '',
        NameOfRepresentative: '',
        CustomerCode: '',
      });
    } else {
      // 등록일때 초기값
      setTicketID(null);
      setFormData({
        EtcSalesType: '',
        CustomerType: '',
        CustomerName: '',
        CustomerCode: '',
        PaymentType: '',
        FromDate: '',
        ToDate: '',
        Quantity: '',
        UnitPrice: '',
        IsTaxFree: false,
        SalesPrice: 0,
        SalesDate: '',
        Remark: '',
      });
      setAddParams({
        SalesType: '',
        KeyIDList: [],
        CustomerCorpRegNumber: '',
        CustomerCorpName: '',
        NameOfRepresentative: '',
        CustomerCode: '',
      });
    }
  };

  useEffect(() => {
    if (selectedCustomInfo.length > 0) {
      setAddParams({
        ...addParams,
        CustomerCorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
        NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
      });
    }
  }, [selectedCustomInfo]);

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        EtcSalesType,
        CustomerType,
        CustomerName,
        PaymentType,
        SalesPrice,
        Remark,
        SalesDate,
        FromDate,
        ToDate,
        Quantity,
        UnitPrice,
        IsTaxFree,
        GarageArea,
        SalesItemName,
      } = formData;
      const { SalesType, KeyIDList, CustomerCode } = addParams;

      const costCenter = isEtcSale ? (CostCenterCode ? CostCenterCode : formData.CostCenterCode) : CostCenterCode;
      const fromDate = isGarageSales ? moment(FromDate).format('YYYY-MM-DD') : '';
      const toDate = isGarageSales ? moment(ToDate).format('YYYY-MM-DD') : '';
      const isConfirmed = await isWithin3Months(fromDate, toDate);
      if (!isConfirmed) {
        return;
      }
      if (ticketID !== null) {
        // 수정
        setLoadPanelVisible(true);
        await salesPayment
          .updateEtcSalesHistory({
            TicketID: ticketID,
            EtcSalesType,
            CustomerType,
            CustomerCode,
            CustomerName,
            PaymentType,
            FromDate: fromDate,
            ToDate: toDate,
            Quantity,
            UnitPrice,
            SalesPrice,
            SalesDate,
            IsTaxFree,
            GarageArea,
            SalesItemName,
            Remark,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '수정되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              onClose();
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      } else {
        // 등록
        setLoadPanelVisible(true);
        await salesPayment
          .addEtcSalesHistory({
            CostCenterCode: costCenter,
            EtcSalesType,
            CustomerType,
            CustomerCode,
            CustomerName,
            PaymentType,
            FromDate: fromDate,
            ToDate: toDate,
            Quantity,
            UnitPrice,
            IsTaxFree,
            SalesPrice,
            SalesDate: SalesDate ? moment(SalesDate).format('YYYY-MM-DD') : '',
            GarageArea,
            SalesItemName,
            Remark,
            RegisterUser: '',
          })
          .then(async res => {
            if (res.isOk) {
              if (isClosePending) {
                await salesPayment
                  .addEtcSalesTicketID({
                    CostCenterCode: costCenter,
                    SalesType,
                    KeyIDList,
                    TicketID: res.data[0][0].TicketID,
                    SalesDate: SalesDate ? moment(SalesDate).format('YYYY-MM-DD') : '',
                    RegisterUser: '',
                  })
                  .then(res => {
                    if (res.isOk) {
                      setLoadPanelVisible(false);
                      notify(
                        {
                          message: '등록되었습니다.',
                          width: 230,
                          position: {
                            at: 'top',
                            my: 'top',
                            of: '#container',
                          },
                        },
                        'success',
                      );
                    } else {
                      setLoadPanelVisible(false);
                      notify(
                        {
                          message: error.errorMsgCheck(res.error.detail),
                          width: 230,
                          position: {
                            at: 'top',
                            my: 'top',
                            of: '#container',
                          },
                        },
                        'error',
                      );
                    }
                  });

                onClose();
                setTimeout(() => {
                  refresh();
                }, 1000);
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '등록되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                onClose();
                setTimeout(() => {
                  refresh();
                }, 1000);
              }
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    },
    [formData, CostCenterCode, ticketID, isClosePending, addParams, 
      isGarageSales, useUnitPrice, useSalesItemName],
  );

  const onClose = () => {
    setIsOpen(false);
    formRef.current.instance.resetValues();
  };

  const handleEtcTypeChanged = e => {
    if (e.value === '007') {
      setIsGarageSales(true);
      setUseUnitPrice(false);
      setUseTaxFree(false);
      setUseSalesItemName(false);
      setFormData({ ...formData, UnitPrice: '', Quantity: '', IsTaxFree: false, SalesItemName: '' });
    } else if (e.value === '004' || e.value === '009') {
      setIsGarageSales(false);
      setUseUnitPrice(true);
      setUseTaxFree(false);
      setUseSalesItemName(false);
      setFormData({ ...formData, FromDate: '', ToDate: '', GarageArea: '', IsTaxFree: false, SalesItemName: '' });
    } else if (e.value === '013' || e.value === '006') {
      setIsGarageSales(false);
      setUseUnitPrice(false);
      setUseTaxFree(true);
      setUseSalesItemName(false);
      setFormData({ ...formData, FromDate: '', ToDate: '', GarageArea: '', UnitPrice: '', Quantity: '', SalesItemName: '' });
    } else if (e.value === '019' || e.value === '020') {
      setIsGarageSales(false);
      setUseUnitPrice(false);
      setUseTaxFree(false);
      setUseSalesItemName(true);
      setFormData({ ...formData, FromDate: '', ToDate: '', GarageArea: '', UnitPrice: '', Quantity: '', IsTaxFree: false });
    } else {
      setIsGarageSales(false);
      setUseUnitPrice(false);
      setUseTaxFree(false);
      setUseSalesItemName(false);
      setFormData({ ...formData, FromDate: '', ToDate: '', GarageArea: '', UnitPrice: '', Quantity: '', IsTaxFree: false, SalesItemName: '' });
    }
  };

  const handleDateFrom = e => {
    if (e.value) {
      setDateValue(e.value);
    }
  };

  const onFocusIn = e => {
    e.event.target.blur();
  };

  useEffect(() => {
    if (selectedCostCenter.length > 0) {
      setFormData({
        ...formData,
        CostCenterCode: selectedCostCenter[0].CostCenterCode,
        CostCenterName: selectedCostCenter[0].CostCenterName,
      });
    }
  }, [selectedCostCenter]);

  return (
    <React.Fragment>
      <form className="add-sales-master-form" onSubmit={onSubmit}>
        <div className="">
          <Form
            ref={formRef}
            formData={formData}
            // labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
            height={775}
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption={ticketID !== null ? '기타 매출유형 수정' : '기타 매출유형 등록'} colCount={2}>
              <GroupItem>
                <SimpleItem
                  colSpan={2}
                  dataField="CostCenterName"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: CostCenterName ? CostCenterName : formData.CostCenterName,
                    readOnly: isEtcSale ? (ticketID === null ? (CostCenterName ? true : false) : true) : true,
                    onFocusIn: onFocusIn,
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          stylingMode: 'text',
                          text: '사업소 선택',
                          onClick: () => {
                            costcenterMasterRef.current.open();
                          },
                        },
                      },
                    ],
                  }}
                >
                  <Label text="사업소" />
                  <RequiredRule message="필수값입니다." />
                </SimpleItem>

                <SimpleItem
                  colSpan={2}
                  dataField="EtcSalesType"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: etcType,
                    valueExpr: 'value',
                    displayExpr: 'id',
                    placeholder: '매출 유형을 선택해주세요',
                    onValueChanged: handleEtcTypeChanged,
                  }}
                >
                  <RequiredRule message="매출 유형을 선택해주세요" />
                  <Label text="매출유형" />
                </SimpleItem>
                <SimpleItem
                  dataField="FromDate"
                  editorType="dxDateBox"
                  visible={isGarageSales ? true : false}
                  editorOptions={{
                    type: 'date',
                    dropDownButtonTemplate: 'dropDownButton',
                    displayFormat: 'yyyy-MM-dd',
                    useMaskBehavior: true,
                    icon: true,
                    placeholder: '시작일 선택',
                    onValueChanged: handleDateFrom,
                  }}
                >
                  <Label text="시작일 (매출 금액 해당 시작일)" />
                  <RequiredRule message="필수 값입니다." />
                </SimpleItem>
                <SimpleItem
                  dataField="ToDate"
                  editorType="dxDateBox"
                  visible={isGarageSales ? true : false}
                  editorOptions={{
                    type: 'date',
                    useMaskBehavior: true,
                    displayFormat: 'yyyy-MM-dd',
                    min: dateValue,
                    placeholder: '종료일 선택',
                    dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
                  }}
                >
                  <Label text="종료일 (매출 금액 해당 종료일)" />
                  <RequiredRule message="필수 값입니다." />
                </SimpleItem>
                <SimpleItem
                  colSpan={1}
                  dataField="GarageArea"
                  editorType="dxNumberBox"
                  visible={isGarageSales ? true : false}
                  editorOptions={{
                    showSpinButtons: true,
                    min: 0,
                    format: '#,##0',
                    readOnly: isClosePending,
                  }}
                >
                  <Label text="차고지면적(㎡)" />
                </SimpleItem>
                <SimpleItem
                  colSpan={1}
                  dataField="Quantity"
                  editorType="dxNumberBox"
                  visible={useUnitPrice ? true : false}
                  editorOptions={{
                    showSpinButtons: true,
                    min: 0,
                    format: '#,##0',
                    readOnly: isClosePending,
                  }}
                >
                  <Label text="수량" />
                </SimpleItem>
                <SimpleItem
                  colSpan={1}
                  dataField="UnitPrice"
                  editorType="dxNumberBox"
                  visible={useUnitPrice ? true : false}
                  editorOptions={{
                    showSpinButtons: true,
                    min: 0,
                    format: '#,##0',
                    readOnly: isClosePending,
                  }}
                >
                  <Label text="단가" />
                </SimpleItem>
                <SimpleItem
                  colSpan={1}
                  dataField="SalesItemName"
                  editorType="dxTextBox"
                  visible={useSalesItemName ? true : false}
                  editorOptions={{
                    readOnly: isClosePending,
                    maxLength: 100
                  }}
                >
                  <Label text="항목명" />
                </SimpleItem>
                <SimpleItem colSpan={1} dataField="IsTaxFree" editorType="dxCheckBox" visible={useTaxFree}>
                  <Label text="부가세 없음" location="left" />
                </SimpleItem>
                <SimpleItem
                  colSpan={2}
                  dataField="PaymentType"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: paymentType,
                    valueExpr: 'value',
                    displayExpr: 'id',
                    placeholder: '결제 유형을 선택해주세요',
                  }}
                >
                  <RequiredRule message="결제 유형을 선택해주세요" />
                  <Label text="결제유형" />
                </SimpleItem>
                <SimpleItem
                  colSpan={2}
                  dataField="SalesPrice"
                  editorType="dxNumberBox"
                  editorOptions={{
                    showSpinButtons: true,
                    min: 0,
                    format: '#,##0',
                    readOnly: isClosePending,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text={isClosePending ? '총금액' : '결제금액'} />
                </SimpleItem>
                <SimpleItem
                  dataField="SalesDate"
                  editorType="dxDateBox"
                  colSpan={2}
                  editorOptions={{
                    type: 'date',
                    dropDownButtonTemplate: 'dropDownButton',
                    displayFormat: 'yyyy-MM-dd',
                    useMaskBehavior: true,
                    icon: true,
                    placeholder: '매출일을 선택해주세요',
                    // onValueChanged: handleDateFrom,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="매출일" />
                </SimpleItem>
                <SimpleItem colSpan={2} dataField="Remark" editorType="dxTextArea">
                  <Label text="비고 (계약 현황 기재)" />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem
                  colSpan={2}
                  dataField="CustomerType"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: customerType,
                    valueExpr: 'value',
                    displayExpr: 'id',
                    placeholder: '고객 유형을 선택해주세요',
                  }}
                >
                  <RequiredRule message="고객 유형을 선택해주세요" />
                  <Label text="고객유형" />
                </SimpleItem>
                <SimpleItem colSpan={2} dataField="CustomerName" editorType="dxTextBox">
                  <Label text="개인 고객이름" />
                </SimpleItem>
                <SimpleItem colSpan={2} dataField="CustomerCode" visible={false}>
                  <Label text="기업 고객코드" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerCorpRegNumber"
                  editorType="dxTextBox"
                  editorOptions={{
                    onFocusIn: onFocusIn,
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          stylingMode: 'text',
                          text: '사업자 선택',
                          onClick: () => {
                            customInfoRef.current.open();
                          },
                        },
                      },
                    ],
                    value: addParams.CustomerCorpRegNumber,
                  }}
                >
                  <Label text="사업자등록번호" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerCorpName"
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                    value: addParams.CustomerCorpName,
                  }}
                >
                  <Label text="회사명" />
                </SimpleItem>
                <SimpleItem
                  dataField="NameOfRepresentative"
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                    value: addParams.NameOfRepresentative,
                  }}
                >
                  <Label text="매입자 대표자 성명" />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions
                  width={'60%'}
                  type={'default'}
                  useSubmitBehavior={true}
                  text={ticketID !== null ? '수정' : '저장'}
                ></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
      <CostcenterMaster ref={costcenterMasterRef} setSelectedCostCenter={setSelectedCostCenter} />
    </React.Fragment>
  );
});

export default AddSalesMaster;
