import React, { useEffect, useState, useRef } from 'react';
import { Column, Pager, Paging, HeaderFilter } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { ticket } from '../../../api';
import { COMPANYCODE } from 'constants/codes';
import CommonDataGrid from 'common/default-data-grid';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import './season-ticket-member-history-mng.scss';

const SeasonTicketMemberHistoryMng = () => {
  const [autoText, setAutoText] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [autoDataSource, setAutoDataSource] = useState([]);
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchParam, setSearchParam] = useState({
    CostCenterCode: '',
    FromDate: '',
    ToDate: '',
  });

  const gridRef = useRef({});
  const autoCompleteRef = useRef({});

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCostCenterForAutoComplete({ CompanyCode: COMPANYCODE, SearchText: '' });
      if (result.isOk) {
        setAutoDataSource(result.data);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await ticket.getSeasonTicketMemberMasterHistory(searchParam);
      if (result.isOk) {
        setDataSource(result.data);
      }
    })();
  }, [searchParam]);

  const onEnterKey = e => {
    setSearchParam({
      ...searchParam,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
    });
  };

  const onSearch = e => {
    setSearchParam({
      ...searchParam,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
    });
  };

  const onItemClick = e => {
    const { CostCenterCode } = e.itemData;
    const { FromDate, ToDate } = dateValue;
    setSearchParam({ CostCenterCode, FromDate, ToDate });
    autoCompleteRef.current.instance.blur();
  };
  const onValueChanged = e => {
    setAutoText(e.value);
  };
  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD');
  };
  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };
  return (
    <div>
      <h2 className={'content-block'}>정기권 회원 이력 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            hoverStateEnabled={true}
            height={650}
            stylingMode="outlined"
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: '정기권 회원 이력.xlsx',
            }}
            isHeader={true}
            headerBefore={[
              <div className="label">등록일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span>~</span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <Autocomplete
                ref={autoCompleteRef}
                labelMode="static"
                stylingMode="outlined"
                dataSource={autoDataSource}
                value={autoText}
                valueExpr="CostCenterName"
                searchExpr="CostCenterName"
                onValueChanged={onValueChanged}
                onEnterKey={onEnterKey}
                showClearButton={true}
                width={200}
                placeholder="P-코드/사업소명"
                onItemClick={onItemClick}
                mode="search"
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <HeaderFilter visible={true} />

            <Paging defaultPageSize={10} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Column
              dataField={'LastUpdateTime'}
              caption={'처리일시'}
              customizeText={customizeText}
              hidingPriority={11}
            />
            <Column dataField={'ActionTypeName'} caption={'처리유형'} hidingPriority={10} />
            <Column dataField={'MemberID'} caption={'회원ID'} hidingPriority={18} />
            <Column dataField={'VehicleRegistration'} caption={'차량번호'} hidingPriority={9} />
            <Column dataField={'CustomerCorpName'} caption={'개인 사업자명'} width={120} hidingPriority={2} />
            <Column dataField={'GroupName'} caption={'회원그룹'} width={110} hidingPriority={5} />
            <Column dataField={'GroupCorpName'} caption={'그룹 사업자명'} width={120} hidingPriority={2} />
            <Column dataField={'MemberName'} width={100} caption={'회원명'} hidingPriority={8} />
            <Column dataField={'MemberTypeName'} caption={'정기권유형'} width={120} hidingPriority={7} />
            <Column dataField={'MemberDivisionName'} width={110} caption={'회원구분'} hidingPriority={4} />
            <Column dataField={'MemberStatusName'} caption={'회원상태'} width={110} hidingPriority={3} />
            <Column dataField={'UseTagName'} caption={'태그사용 유무'} width={120} hidingPriority={2} />
            <Column dataField={'TagRentPrice'} caption={'태그비용'} alignment={'left'} width={110} hidingPriority={1} />
            <Column dataField={'MobliePhoneNo'} caption={'핸드폰번호'} width={110} hidingPriority={4} />
            <Column dataField={'CarType'} caption={'차종'} width={120} hidingPriority={5} />
            <Column dataField={'EmailAddress'} caption={'이메일'} width={150} hidingPriority={3} />
            <Column dataField={'TelephoneNo'} caption={'전화번호'} width={110} hidingPriority={5} />
            <Column dataField={'RegisterUser'} caption={'등록자'} width={120} hidingPriority={2} />
            <Column
              dataField={'RegisterDate'}
              caption={'등록일'}
              hidingPriority={1}
              customizeText={customizeText}
              // cellRender={dateCellRender}
            />
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default SeasonTicketMemberHistoryMng;
