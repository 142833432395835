import React, { useCallback, useEffect, useRef, useState } from 'react';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Column, Scrolling, Selection } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';
import { Button } from 'devextreme-react/button';
import { parking, ticket } from 'api';
import { notifyS } from 'common/popup/sales-common-util';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import moment from 'moment';
import DateBox from 'devextreme-react/date-box';
import { COMPANYCODE } from 'constants/codes';
import CostGrid from './cost-grid';
import './parking-cost-mgmt.scss';

const FIXED = 'Fixed';
const FLOATING = 'Floating';
const OTHERS = 'Others';
const DEFAULT_SELECTED_COST_CENTER_CODE = 'BtoB';

const ParkingCostMgmt = () => {
  const centerGridRef = useRef({});
  const fixedRef = useRef({});
  const floatingRef = useRef({});
  const othersRef = useRef({});

  const [dataSourceOrg, setDataSourceOrg] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dsFixed, setDsFixed] = useState([]);
  const [dsFloating, setDsFloating] = useState([]);
  const [dsOthers, setDsOthers] = useState([]);

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY/MM/DD'),
    CostCenterCode: DEFAULT_SELECTED_COST_CENTER_CODE,
  });

  const [selectedKeys, setSelectedKeys] = useState([DEFAULT_SELECTED_COST_CENTER_CODE]);

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const handleSalesMonth = e => {
    setSearchData({
      ...searchData,
      SalesMonth: e.value,
    });
    getCostData(e.value, searchData.CostCenterCode);
  };

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCostCenterForAutoComplete({ CompanyCode: COMPANYCODE, SearchText: '' });
      if (result.isOk) {
        const newDataSource = result.data;
        setDataSourceOrg(newDataSource);
        setDataSource(newDataSource);
        const firstSelectedKeys = newDataSource.length > 0 ? [newDataSource[0].CostCenterCode] : [];
        setSelectedKeys(firstSelectedKeys);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  }, []);

  const onSelectionChangedCenter = e => {
    if (e.selectedRowsData.length > 0) {
      setSearchData({
        ...searchData,
        CostCenterCode: e.selectedRowKeys[0],
      });
      setSelectedKeys(e.selectedRowKeys);
      getCostData(searchData.SalesMonth, e.selectedRowKeys[0]);
    }
  };

  const getCostData = useCallback(async (salesMonth, costCenterCode) => {
    setLoadPanelVisible(true);
    const params = {
      CostCenterCode: costCenterCode,
      SalesMonth: moment(salesMonth, 'YYYY/MM/DD').format('YYYYMM'),
    };

    const result = await parking.getParkingCostManagementData(params);
    if (!result.isOk) {
      notifyS(JSON.parse(result.error.detail).sqlMessage);
      setLoadPanelVisible(false);
      return;
    }
    const tmpData = result.data && result.data[0] ? result.data[0] : [];
    const tmpFixed = [];
    const tmpFloating = [];
    const tmpOthers = [];
    tmpData.forEach((row, index) => {
      const tmpTarget =
        row.CostGroupCode === FIXED ? tmpFixed : row.CostGroupCode === FLOATING ? tmpFloating : tmpOthers;
      tmpTarget.push(row);
    });
    setDsFixed(tmpFixed);
    setDsFloating(tmpFloating);
    setDsOthers(tmpOthers);
    setLoadPanelVisible(false);
  }, []);

  const saveExpense = useCallback(async () => {
    const tmpFixed = fixedRef.current.getDataSource();
    const tmpFloating = floatingRef.current.getDataSource();
    const tmpOthers = othersRef.current.getDataSource();

    setLoadPanelVisible(true);
    const params = {
      CostCenterCode: searchData.CostCenterCode,
      SalesMonth: moment(searchData.SalesMonth, 'YYYY/MM/DD').format('YYYYMM'),
      FixedItemListJson: JSON.stringify(tmpFixed),
      FloatingItemListJson: JSON.stringify(tmpFloating),
      OthersItemListJson: JSON.stringify(tmpOthers),
    };
    const result = await parking.saveParkingCostManagementData(params);
    if (!result.isOk) {
      setLoadPanelVisible(false);
      notifyS(JSON.parse(result.error.detail).sqlMessage);
      return;
    }

    notifyS('저장되었습니다.', 'success');
    setLoadPanelVisible(false);
  }, [searchData]);

  return (
    <div>
      <h2 className={'content-block'}>사업소 비용 관리</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings parking-cost-mgmt'} style={{ paddingTop: '10px' }}>
          <Toolbar id={'toolbar-menu'}>
            <ToolbarItem location={'before'}>
              <div style={{ width: '40px' }}>등록월</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
                onValueChanged={handleSalesMonth}
                value={searchData.SalesMonth}
                elementAttr={{ id: 'search-date-box' }}
              />
              &nbsp;
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button
                icon="check"
                text="저장"
                width={80}
                type="normal"
                stylingMode="contained"
                onClick={() => saveExpense()}
              />
            </ToolbarItem>
          </Toolbar>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div style={{ marginRight: '5px', maxWidth: '50%' }}>
              <CommonDataGrid
                gridRef={centerGridRef}
                className={'dx-card wide-card grid-with-status'}
                dataSource={dataSource}
                showBorders={true}
                allowColumnResizing={true}
                columnHidingEnabled={false}
                hoverStateEnabled={true}
                height={775}
                isHeader={true}
                isExcel={true}
                excelOptions={{
                  sheetName: 'sheet',
                  fileName: '사업소.xlsx',
                  customizeCell: {
                    date: ['RegisterDate', 'LastUpdateDate'],
                  },
                }}
                allowColumnReordering={true}
                headerBefore={[]}
                headerAfter={[]}
                onSelectionChanged={onSelectionChangedCenter}
                keyExpr="CostCenterCode"
                selectedRowKeys={selectedKeys}
              >
                <Scrolling mode="virtual" rowRenderingMode="virtual" />
                <Selection mode="single" selectAllMode="pages" />
                <Column dataField={'CostCenterCode'} caption={'P코드'} width={120} />
                <Column dataField={'CostCenterName'} caption={'사업소명'} width={120} />
              </CommonDataGrid>
            </div>

            <div style={{ maxWidth: '50%' }}>
              <CostGrid title={'고정경비'} height={316} dsCost={dsFixed} ref={fixedRef} />
              <CostGrid title={'변동경비'} height={240} dsCost={dsFloating} ref={floatingRef} />
              <CostGrid title={'기타'} height={110} dsCost={dsOthers} ref={othersRef} />
            </div>
          </div>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default ParkingCostMgmt;
