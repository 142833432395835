import { React, useMemo, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import { PivotGrid, FieldChooser, FieldPanel } from 'devextreme-react/pivot-grid';
import { Column, Pager, Paging, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { aggregation } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import './view-rpa-schedulejob-history.scss';

const ViewRPAJobHistory = () => {
  const maingridRef = useRef({});
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });
  const [search, setSerch] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
  });

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        const result = await aggregation.getRPAScheduleJobHistory(search);
        return result.data;
      },
    });

    return customDataSource;
  }, [search]);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };

  const onSearch = () => {
    setSerch({
      FromDate: dateValue.fromDate,
      ToDate: dateValue.toDate,
    });
  };
  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    if (data) {
      const { Result } = data;
      const tr = cellElement;
      if (Result.includes('에러') && tr) {
        cellElement.style.backgroundColor = 'rgb(252 231 231)';
        cellElement.style.color = 'rgb(236 74 74)';
      }
    }
  }, []);

  const onCellPreparedDetail = useCallback(({ data, displayValue, cellElement }) => {
    if (data) {
      const { Result } = data;
      const tr = cellElement;
      if (Result.includes('실패') && tr) {
        cellElement.style.backgroundColor = 'rgb(252 231 231)';
        cellElement.style.color = 'rgb(236 74 74)';
      }
    }
  }, []);

  return (
    <div>
      <h2 className={'content-block'}>RPA 실행 이력</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={maingridRef}
            className={'dx-card wide-card rpa-dx-data-row'}
            dataSource={dataSource}
            showBorders={true}
            allowColumnResizing={true}
            columnAutoWidth={false}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={650}
            isHeader={true}
            wordWrapEnabled={true}
            isExcel={true}
            headerBefore={[
              <div className="label">대상날짜 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />,
              <div className="label">~</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                onValueChanged={handleToDate}
                value={dateValue.toDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
            excelOptions={{
              sheetName: 'sheet',
              fileName: 'RPA실행로그.xlsx',
            }}
            onCellPrepared={onCellPrepared}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
            <Column dataField={'TimeKey'} caption={'등록일'} width={160} customizeText={customizeText} />
            <Column dataField={'Result'} caption={'결과'} width={120} />
            <Column dataField={'Log'} caption={'실행로그'} />
            {/* <Summary>
             <TotalItem column="Result" summaryType="count" displayFormat="총 {0}건" />
             </Summary> */}
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default ViewRPAJobHistory;
