import axios from 'axios';
import { handleAuthError } from './error';
const userId = localStorage.getItem('userId');

export async function getCodeType() {
  try {
    const result = await axios.post(`master/codemaster/searchCodeGroup`, {
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function saveCodeMaster(params) {
  try {
    const result = await axios.post(`master/codemaster/insertCodeMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function updateCodeMaster(params) {
  try {
    const result = await axios.post(`master/codemaster/updateCodeMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getAccountMaster(params) {
  try {
    const result = await axios.post(`master/searchAccountMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getVender(params) {
  try {
    const result = await axios.post(`master/searchVendorMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getCodeMasterByCodeAndCodeGroup(CodeGroup, Code) {
  try {
    const result = await axios.post(`master/codemaster/searchCodeMasterByCode`, {
      CodeGroup,
      Code,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function searchCodeMaster(params) {
  try {
    const result = await axios.post(`master/codemaster/searchCodeMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function searchCodeMasterAll(params) {
  try {
    const result = await axios.post(`master/codemaster/searchCodeMasterAll`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}


export async function searchCostCenterMaster(params) {
  try {
    const result = await axios.post(`master/searchCostCenterMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function updateCustomerMaster(params) {
  try {
    const result = await axios.post(`master/updateCustomerMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
