import React, { useCallback, useMemo, useState, useImperativeHandle, useEffect, useContext, useRef } from 'react';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { AuthContext } from 'contexts/auth';
import { cellStatus } from '../common/common';
import { salesPayment, aggregation, error } from 'api';
import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import { Popup } from 'devextreme-react/popup';

import RemarkPopup from '../common/remark-popup';
import CustomStore from 'devextreme/data/custom_store';
import ContextMenu from 'devextreme-react/context-menu';
import CommonDataGrid from '../common/index';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

/*
isSalesPayment : 매출 대사 관리
isTaxinvoice, isSearch : 세금계산서 발행
isClosePending : 미처리 매출/결제 마감
isCheckSales : 세금계산서 매출 대사
*/

const CMS = React.forwardRef((props, ref) => {
  const {
    setCRowdata,
    susprcptRef,
    inquiryDate,
    costCenter,
    cmsRef,
    isClosePending,
    height,
    isCheckSales,
    searchData,
    isSalesPayment,
    onCancelClick,
    setSelectedAccountingNo,
    setSelectedID,
    SetDataSourcePayment,
    isInquiryMonthOnly,
  } = props;
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectSum, setSelectSum] = useState(0);
  const popupRef = useRef({});

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    focusChange,
  }));
  const { user } = useContext(AuthContext);
  const isRoleSuperAdmin = user.RoleCodeStr.includes('Role.SuperAdmin');

  const focusChange = async accountingNoList => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (accountingNoList.includes(obj.AccountingNo)) {
        KeyIDs.push(obj.KeyID);
      }
    });
    cmsRef.current.instance.selectRows(KeyIDs);
  };

  const focusChangeSelf = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    cmsRef.current.instance.selectRows(KeyIDs);
  };

  const [customizedColumns, setCustomizedColumns] = useState([]);

  useEffect(() => {
    const customizedCMS = localStorage.getItem('customizedCMS');
    if (customizedCMS) {
      const tmp_columns = JSON.parse(customizedCMS);
      setCustomizedColumns(tmp_columns);
    }
  }, []);
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectionChanged = () => {
    const rowdata = cmsRef.current.instance.getSelectedRowsData();
    setCRowdata(rowdata);
    const sum = rowdata.reduce((a, c) => {
      return a + c.TransactionAmount;
    }, 0);
    setSelectSum(sum);
  };

  const handleParams = (costCenter, inquiryDate, searchData) => {
    if (isClosePending) {
      const response = { CostCenter: costCenter, IsPostponed: 'Y' };
      response[isInquiryMonthOnly ? 'InquiryMonthOnly' : 'InquiryMonth'] = inquiryDate;
      return response;
    } else if (isCheckSales) {
      const { FromDate, ToDate, CostCenterCode } = searchData;
      return { FromDate, ToDate, CostCenter: CostCenterCode, IsforTaxAccounting: 'Y' };
    } else {
      return { InquiryDate: inquiryDate, CostCenter: costCenter };
    }
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const params = handleParams(costCenter, inquiryDate, searchData);
        if (costCenter || (searchData && searchData.CostCenterCode)) {
          cmsRef.current.instance.clearSelection();
          const result = await salesPayment.getCPaymentDataDetail(params);
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenter, searchData, isInquiryMonthOnly]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;

    return Status === 'A' || Status === 'O' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const handleDate = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD');
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { AccountingNo } = data;
      AccountingNo ? setSelectedAccountingNo(AccountingNo) : setSelectedAccountingNo('N/A');
      setSelectedID('');
      if (AccountingNo) {
        focusChangeSelf(AccountingNo);
      }
      SetDataSourcePayment([]);
    }
  };

  const onContentReady = e => {
    var columnChooserView = e.component.getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.on('hiding', () => {
        const rawData = columnChooserView.getColumns();
        const dataFieldsArr = rawData.map(column => column.dataField);
        localStorage.setItem('customizedCMS', JSON.stringify(dataFieldsArr));
      });
    }
  };

  const onDeleteClick = e => {
    const selectedData = cmsRef.current.instance.getSelectedRowsData();
    const KeyIDs = [];
    // 조건 입력 ex) 매출상태에 따라 삭제
    if (selectedData.length === 0) {
      return alert('결제 내역을 선택해주세요');
    }
    const result = confirm('<i> 결제를 삭제하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        selectedData.forEach(obj => {
          KeyIDs.push(obj.KeyID);
        });
        const keyIDsParam = KeyIDs.length > 0 ? KeyIDs.join(';') : '';
        setLoadPanelVisible(true);
        await salesPayment
          .deletePaymentByKeyID({
            KeyID: keyIDsParam
          })
          .then(res => {
            setLoadPanelVisible(false);
            if (res.isOk) {
              notify(
                {
                  message: '결제 내역이 삭제되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
            } else {
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  };
  const refresh = () => {
    cmsRef.current.instance.refresh();
  };

  const handleRemarkSubmit = async remark => {
    const selectedData = cmsRef.current.instance.getSelectedRowsData();
    const KeyIDs = [];
    setPopupVisible(false);
    selectedData.forEach(obj => {
      KeyIDs.push(obj.KeyID);
    });
    const keyIDsParam = KeyIDs.length > 0 ? KeyIDs.join(';') : '';
    setLoadPanelVisible(true);
    try {
      const res = await aggregation.registerSuspenseReceipts({
        CostCenterCode: costCenter,
        InquiryDate: inquiryDate,
        KeyID: keyIDsParam,
        Remark: remark,
      });
      setLoadPanelVisible(false);
      if (res.isOk) {
        notify(
          {
            message: '가수금으로 등록되었습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'success',
        );
        cmsRef.current.instance.refresh();
        susprcptRef.current.instance.refresh();
      } else {
        notify(
          {
            message: error.errorMsgCheck(res.error.detail),
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    } catch (error) {
      setLoadPanelVisible(false);
      console.error('오류', error);
    }
  };

  const onClick = e => {
    const selectedData = cmsRef.current.instance.getSelectedRowsData();
    if (selectedData.length === 0) {
      return alert('결제를 선택해주세요');
    }
    setPopupVisible(true);
  };

  const onClose = () => {
    setPopupVisible(false);
  };

  return (
    <>
      <CommonDataGrid
        gridRef={cmsRef}
        className={'dx-card wide-card minimum-padding'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        height={height || 480}
        onContentReady={onContentReady}
        width={'100%'}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        onCellClick={onCellClick}
        isHeader={true}
        isExcel={true}
        headerAfter={[
          <Button text="가수금 등록" onClick={onClick} />,
          <Button
            text="결제삭제"
            width={90}
            type="normal"
            stylingMode="contained"
            onClick={onDeleteClick}
            visible={isRoleSuperAdmin ? true : false}
          />,
        ]}
        headerBefore={[
          isCheckSales ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
              <i className="dx-icon-info dx-icon-customicon"></i>
              <p>사업소명을 먼저 검색해주세요</p>
            </div>
          ) : (
            ''
          ),
        ]}
        excelOptions={{
          sheetName: 'sheet',
          fileName: 'CMS.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        {isSalesPayment && (
          <Column
            dataField={'TransactionDateShort'}
            name="TransactionDate1"
            caption={'입금날짜'}
            customizeText={handleDate}
            width={'90'}
          />
        )}
        {isClosePending && <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />}
        {isClosePending && <Column dataField={'HiParkingID'} caption={'P코드'} width={'100'} />}
        <Column
          dataField={'TransactionDate'}
          name="TransactionDate2"
          caption={'입금시간'}
          customizeText={customizeText}
          width={'142'}
          visible={!customizedColumns.includes('TransactionDate')}
        />
        <Column
          dataField={'StatusName'}
          caption={'매출상태'}
          width={'86'}
          cellRender={isSalesPayment ? editCellRender : ''}
          visible={!customizedColumns.includes('StatusName')}
        />
        <Column
          dataField={'TransactionAmount'}
          caption={'입금금액'}
          format="#,##0 원"
          width={'104'}
          visible={!customizedColumns.includes('TransactionAmount')}
        />
        <Column
          dataField={'TransactionSummary'}
          caption={'Summary'}
          width={'100'}
          visible={!customizedColumns.includes('TransactionSummary')}
        />
        <Column dataField={'Branch'} caption={'Branch'} width={'100'} visible={!customizedColumns.includes('Branch')} />
        <Column
          dataField={'GiroCode'}
          caption={'지로'}
          width={'100'}
          visible={!customizedColumns.includes('GiroCode')}
        />
        <Column
          dataField={'ExtraField1'}
          caption={'비고'}
          width={'100'}
          visible={!customizedColumns.includes('ExtraField1')}
        />
        <Column
          dataField={'BankName'}
          caption={'거래은행'}
          width={'100'}
          visible={!customizedColumns.includes('BankName')}
        />
        <Column
          dataField={'BankAccountNo'}
          caption={'계좌'}
          width={'100'}
          visible={!customizedColumns.includes('BankAccountNo')}
        />
        <Summary>
          <TotalItem column="SalesPrice" displayFormat="선택합계 :" showInColumn="TransactionDate" />
          <TotalItem customizeText={customizeSelectTotal} column="TransactionAmount" showInColumn="TransactionDate" />
          <TotalItem column="StatusName" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="TransactionAmount" displayFormat="전체합계 :" showInColumn="SalesPrice" />
          <TotalItem column="TransactionAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
        </Summary>
      </CommonDataGrid>
      <PSLoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '.content' }} visible={loadPanelVisible} />
      <Popup onHiding={onClose} visible={popupVisible} showTitle={false} width="25%" height={310}>
        <RemarkPopup ref={popupRef} setPopupVisible={setPopupVisible} handleRemarkSubmit={handleRemarkSubmit} />
      </Popup>
    </>
  );
});

export default React.memo(CMS);
