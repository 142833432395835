import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import { Button } from 'devextreme-react/button';
import { aggregation, error } from '../../../api';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';

import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

import SalesConfirmSummary from './component/component/salesConfirmSummary';
import SuspenseReceiptData from './component/component/suspenseReceiptData';
import TaxInvoicePayment from './component/component/taxInvoicePayment';
import CMSInterestData from './component/component/cmsInterestData';
import SalesTotalSummary from './component/component/salesTotalSummary';

import { getNormalDateFormat } from 'utils/common';

const MonthlyConfirmDataMgmt = () => {
  const thisMonth = moment(new Date()).format('yyyy-MM');
  const [salesMonth, setDateValue] = useState(thisMonth);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [salesConfirmDataSource, setSalesConfirmDataSource] = useState([]);
  const [taxInvoicePaymentDataSource, setTaxInvoicePaymentDataSource] = useState([]);
  const [suspenseReceiptDataSource, setSuspenseReceiptDataSource] = useState([]);
  const [cmsInterestDataDataSource, setCmsInterestDataDataSource] = useState([]);
  const [totalSummaryDataSource, setTotalSummaryDataSource] = useState([]);
  const [totalSummaryDataSource2, setTotalSummaryDataSource2] = useState([]);
  const [searchData, setSearchData] = useState({
    SalesMonth: salesMonth,
  });

  const [selectedIDSeq, setSelectedIDSeq] = useState('');
  const gridRef = useRef({});

  const dataSource = useMemo(async () => {
    try {
      const result = await aggregation.getSAPCofirmationDataMonthly(searchData);
      setSalesConfirmDataSource(result.data[0]);
      setTaxInvoicePaymentDataSource(result.data[1]);
      setSuspenseReceiptDataSource(result.data[2]);
      setCmsInterestDataDataSource(result.data[3]);
      setTotalSummaryDataSource(result.data[4]);
      setTotalSummaryDataSource2(result.data[5]);
      return result.data;
    } catch (e) {
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  }, [searchData]);

  const callSearchAPI = () => {
    setSearchData({
      SalesMonth: salesMonth,
    });
  };

  const onSearch = e => {
    callSearchAPI();
  };

  const onGenerate = useCallback(
    async e => {
      const dataType = [
        { idx: 0, name: 'SalesSummary' },
        { idx: 1, name: 'TaxInvoicePayment' },
        { idx: 2, name: 'SRSummary' },
        { idx: 3, name: 'CMSInterest' },
      ];
      const DataType = dataType.filter(a => a.idx === selectedTabIndex)[0].name;
      // 생성 버튼 클릭 시
      await aggregation.GenerateSalesSummaryForSAP({ SalesMonth: salesMonth, DataType }).then(res => {
        if (res.isOk) {
          notify(
            {
              message: '생성 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          callSearchAPI();
        } else {
          notify(
            {
              message: error.errorMsgCheck(res.error.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      });
    },
    [selectedTabIndex, salesMonth],
  );
  const customizeText = cellInfo => {
    return cellInfo.value === '' ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const handleSalesMonth = e => {
    const salesMonth = moment(getNormalDateFormat(e.value)).format('YYYY-MM');
    setDateValue(salesMonth);
  };
  const onSelectionChanged = e => {
    if (e.selectedRowsData[0]) {
      setSelectedIDSeq(e.selectedRowsData[0].DocumentNoSEQ);
    }
  };
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    // cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectedIndexChange = e => {
    setSelectedTabIndex(e);
  };

  return (
    <React.Fragment>
      <div className="content-block detail-title left-right-header">
        <h2> Hi-DMS 월마감 데이터 관리</h2>
      </div>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <div className="label">매출월 :</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                dataField="SalesMonth"
                value={salesMonth}
                width={100}
                onValueChanged={handleSalesMonth}
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
              />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button icon="plus" text="생성" width={120} type="normal" stylingMode="contained" onClick={onGenerate} />
            </ToolbarItem>
          </Toolbar>
          <div style={{ paddingTop: '5px' }}>
            <TabPanel deferRendering={false} height={650} onSelectedIndexChange={onSelectedIndexChange}>
              <Item title="요약" name="background">
                <SalesTotalSummary dataSource={totalSummaryDataSource} dataSource2={totalSummaryDataSource2} />
              </Item>
              <Item title="일반 매출/수금">
                <SalesConfirmSummary dataSource={salesConfirmDataSource} />
              </Item>
              <Item title="세금계산서수금">
                <TaxInvoicePayment dataSource={taxInvoicePaymentDataSource} />
              </Item>
              <Item title="가수금">
                <SuspenseReceiptData dataSource={suspenseReceiptDataSource} />
              </Item>
              <Item title="CMS 이자수익">
                <CMSInterestData dataSource={cmsInterestDataDataSource} />
              </Item>
            </TabPanel>
          </div>
          <PSLoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '.content' }} visible={loadPanelVisible} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MonthlyConfirmDataMgmt;
