import React, { useState, useRef, useCallback, useMemo } from 'react';
import moment from 'moment';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import DateBox from 'devextreme-react/date-box';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { cashbillManualLink } from 'constants/hyperlink';
import { Help } from 'components';
import notify from 'devextreme/ui/notify';

import { salesPayment } from 'api';

import ScrollView from 'devextreme-react/scroll-view';
import GeneralTicket from './generalTicket';
import SeasonTicket from './seasonTicket';
import EtcSales from './etcSales';
import CashbillHistory from './cashbillHistory';
import AddCashbill from './popup/AddCashbill';

const CashbillMng = () => {
  const [gRowdata, setGRowdata] = useState();
  const [sRowdata, setSRowdata] = useState();
  const [eRowdata, setERowdata] = useState();

  const [gDataSource, setGDataSource] = useState([]);
  const [sDataSource, setSDataSource] = useState([]);
  const [eDataSource, setEDataSource] = useState([]);

  const [tabIdx, setTabIdx] = useState(0);
  const [tabIdxes, setTabIdxes] = useState([]);
  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [helpVisible, setHelpVisible] = useState(false);

  //검색
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(3, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCostCenter: '',
    SearchApprovalNo: '',
    SearchVehicleNumber: '',
    SearchCardNo: '',
    IsforRecieveTaxAccounting: 'Y',
  });
  const [text, setText] = useState({
    SearchCostCenter: '',
    SearchApprovalNo: '',
    SearchVehicleNumber: '',
    SearchCardNo: '',
    IsforRecieveTaxAccounting: 'Y',
  });

  // 팝업
  const [isOpen, setIsOpen] = useState(false);

  //ref
  const generalTicketRef = useRef({});
  const seasonTicketRef = useRef({});
  const etcSalesRef = useRef({});
  const cashbillHistoryRef = useRef({});
  const helpRef = useRef({});
  const addCashbillRef = useRef({});

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    callAPI();
  };

  const callAPI = refreshIdx => {
    const idx = refreshIdx ? refreshIdx : tabIdx;
    const searchParam = {
      ...searchData,
      SearchCostCenter: text.SearchCostCenter,
      SearchApprovalNo: text.SearchApprovalNo,
      SearchVehicleNumber: text.SearchVehicleNumber,
      SearchCardNo: text.SearchCardNo,
      IsforRecieveTaxAccounting: 'Y',
    };
    if (idx === 0) {
      getGDataSource(searchParam);
    } else if (idx === 1) {
      getSDataSource(searchParam);
    } else if (idx === 2) {
      getEDataSource(searchParam);
    }
  };

  const getGDataSource = searchParam => {
    (async () => {
      const result = await salesPayment.getParkingSalesDataDetaiCashConfirmed(searchParam);
      if (result.isOk) {
        setGDataSource(result.data);
      } else {
        notify(
          {
            message: result.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );

        setGDataSource([]);
      }
    })();
  };
  const getSDataSource = searchParam => {
    (async () => {
      const result = await salesPayment.getSeasonTicketSalesDataDetailCashConfirmed(searchParam);
      if (result.isOk) {
        setSDataSource(result.data);
      } else {
        setSDataSource([]);
      }
    })();
  };
  const getEDataSource = searchParam => {
    (async () => {
      const result = await salesPayment.getEtcSalesDataDetailCashConfirmed(searchParam);
      if (result.isOk) {
        setEDataSource(result.data);
      } else {
        setEDataSource([]);
      }
    })();
  };

  const onHiding = () => {
    setIsOpen(false);
    setHelpVisible(false);
  };

  // 현금영수증 발행
  const addCashbill = () => {
    const getSelectedRowsData = ref => (ref.current.instance ? ref.current.instance.getSelectedRowsData() : []);
    const gRowdata = getSelectedRowsData(generalTicketRef);
    const sRowdata = getSelectedRowsData(seasonTicketRef);
    const eRowdata = getSelectedRowsData(etcSalesRef);
    const totalSelectedRows = [...gRowdata, ...sRowdata, ...eRowdata];
    const refreshTabs = [];

    const sumCashPrice = totalSelectedRows.reduce((a, c) => {
      return a + c.CashPrice;
    }, 0);
    const isNotCash = sumCashPrice === 0;
    const isNotSelected = totalSelectedRows.length === 0;
    const isDummy = totalSelectedRows.some(a => a.CashBillID === '제외 처리');

    if (isNotSelected) {
      return alert('매출 내역을 선택해주세요');
    } else if (isNotCash) {
      return alert('금액을 확인해주세요');
    } else if (isDummy) {
      return alert('제외 처리된 현금영수증은 발행 불가합니다.');
    }
    if (gRowdata.length > 0) {
      refreshTabs.push(0);
    }
    if (sRowdata.length > 0) {
      refreshTabs.push(1);
    }
    if (eRowdata.length > 0) {
      refreshTabs.push(2);
    }
    setTabIdxes(refreshTabs);
    addCashbillRef.current.open(totalSelectedRows);
  };

  // 리프레쉬 및 선택 해제
  const refresh = useCallback(async () => {
    await Promise.all(tabIdxes.map(a => callAPI(a)));
    cashbillHistoryRef.current.refresh();
  }, [tabIdxes]);

  const clearSelection = useCallback(() => {
    const instancesToClearSelection = [generalTicketRef, seasonTicketRef, etcSalesRef];
    instancesToClearSelection.forEach(ref => {
      if (ref.current.instance) {
        ref.current.instance.clearSelection();
      }
    });
  }, []);

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onHelpClick = () => {
    setHelpVisible(true);
    helpRef.current.open('cashbill');
  };

  const onSelectedIndexChange = e => {
    setTabIdx(e);
  };
  return (
    <div>
      <div className="content-block detail-title left-right-header">
        <h2>현금영수증 발행</h2>
        <div>
          {' '}
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={'/#/sales-mng/cashbill-list-view'}
            target="blank"
          >
            현금영수증 상세조회 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={cashbillManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789', paddingRight: 15 }}></i>
          <Button icon="help" text="현금영수증 처리절차" onClick={onHelpClick} />
        </div>
      </div>
      <div className={'content-block'}>
        {/* <div className={'dx-card'} style={{ padding: '20px 40px' }}> */}
        <div style={{ display: 'flex', marginTop: '3px' }}>
          <div
            className={'dx-card'}
            style={{ marginRight: '5px', maxWidth: '50%', padding: '20px 20px', borderRadius: '5px' }}
          >
            <Toolbar>
              <ToolbarItem location={'before'}>
                <div className="label">매출수금일 :</div>
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="FromDate"
                  onValueChanged={handleFromDate}
                  value={dateValue.fromDate}
                />
              </ToolbarItem>
              <ToolbarItem location={'before'} text="~" />
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="ToDate"
                  min={dateValue.fromDate}
                  value={dateValue.toDate}
                  onValueChanged={handleToDate}
                  dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
                />
              </ToolbarItem>
              <ToolbarItem location={'after'}>
                <Button text="조회" onClick={onEnterKey} />
              </ToolbarItem>
              <ToolbarItem location={'after'}>
                <Button text="현금영수증 발행" onClick={addCashbill} />
              </ToolbarItem>
            </Toolbar>
            <Toolbar>
              <ToolbarItem location={'before'}>
                <TextBox
                  label="SearchCostCenter"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="P-코드/사업소코드/사업소명"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={220}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  label="SearchApprovalNo"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="승인번호/계좌번호"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={150}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  label="SearchCardNo"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="카드번호"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={150}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  label="SearchVehicleNumber"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="차량번호/이름"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={150}
                  mode="search"
                />
              </ToolbarItem>
            </Toolbar>
            <TabPanel onSelectedIndexChange={onSelectedIndexChange}>
              <Item title="일반권" badge={gRowdata}>
                <GeneralTicket
                  generalTicketRef={generalTicketRef}
                  setGRowdata={setGRowdata}
                  isCashbill
                  dataSource={gDataSource}
                />
              </Item>
              <Item title="정기권" badge={sRowdata}>
                <SeasonTicket
                  seasonTicketRef={seasonTicketRef}
                  setSRowdata={setSRowdata}
                  isCashbill
                  dataSource={sDataSource}
                />
              </Item>
              <Item title="기타" badge={eRowdata}>
                <EtcSales etcSalesRef={etcSalesRef} setERowdata={setERowdata} isCashbill dataSource={eDataSource} />
              </Item>
            </TabPanel>
          </div>
          <div className={'dx-card'} style={{ maxWidth: '50%', padding: '20px 20px', borderRadius: '5px' }}>
            <CashbillHistory ref={cashbillHistoryRef} refresh={refresh} setLoadPanelVisible={setLoadPanelVisible} />
          </div>
        </div>
      </div>
      {/* </div> */}
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width={500} height={430}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <AddCashbill
              ref={addCashbillRef}
              setIsOpen={setIsOpen}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onHiding={onHiding} visible={helpVisible} showTitle={false} width="60%" height="84%">
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <Help setHelpVisible={setHelpVisible} ref={helpRef} />
          </div>
        </ScrollView>
      </Popup>
      <div style={{ zIndex: '1000001' }}>
        <PSLoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ top: '.content' }}
          onHiding={hideLoadPanel}
          visible={loadPanelVisible}
        />
      </div>
    </div>
  );
};

export default CashbillMng;
