import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { seasonticketUploadManualLink } from 'constants/hyperlink';
import { Button } from 'devextreme-react/button';
import { COMPANYCODE } from 'constants/codes';
import { ticket, error } from 'api';
import DataSource from 'devextreme/data/data_source';
import CommonDataGrid from 'common/default-data-grid';
import ExcelImport from 'common/excel-import';
import ExcelExport from 'common/excel-export';
import notify from 'devextreme/ui/notify';
/*

- 현재는 첫번쨰 시트만 읽어옵니다.
- fieldColumn : 컬럼 개수 대로 ['A', 'B', 'C',...'N']

*/

const ExcelUploadSesonTicketMember = () => {
  const dataGridRef = useRef({});
  const [CostCenterCode, setCostCenterCode] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [autoText, setAutoText] = useState('');
  const [autoDataSource, setAutoDataSource] = useState([]);
  const [headerText, setHeaderText] = useState([]);

  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  // 컬럼 정의
  const columns = [
    'MemberStatusName',
    'VehicleRegistration',
    'MemberTypeName',
    'StandardPrice',
    'MemberDivisionName',
    'MemberName',
    'GroupName',
    'CorpRegNumber',
    'UseTag',
    'TagRentPrice',
    'MobliePhoneNo',
    'TelephoneNo',
    'CarType',
    'EmailAddress',
  ];

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCostCenterForAutoComplete({ CompanyCode: COMPANYCODE, SearchText: '' });
      if (result.isOk) {
        setAutoDataSource(result.data);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
    result();
  }, []);

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();

      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const importMapFunc = a => {
    const returnValue = {};
    console.log(columns);
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const saveExcel = () => {
    if (autoText === '' || autoText === null) {
      alert('사업소를 입력해주세요');
    } else {
      setLoadPanelVisible(true);
      ticket
        .uploadSeasonTicketMemberExcel({
          CostCenterCode,
          ItemListJson: JSON.stringify(dataSource),
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '처리되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    }
  };

  const onItemClick = e => {
    setCostCenterCode(e.itemData.CostCenterCode);
  };
  const onValueChanged = e => {
    setAutoText(e.value);
  };
  const handleImport = ExcelOrganization => {
    setShowPane(true);
    setDataSource(ExcelOrganization);
    setShowPane(false);
  };
  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);
  const cellStatus = (data, displayValue, cellElement) => {
    if (data) {
      const { StatusName, Status } = data;
      const tr = cellElement;
      if (displayValue === StatusName) {
        if (Status === 'A') {
          cellElement.style.backgroundColor = 'rgb(229 248 240)';
          cellElement.style.color = 'rgb(51 202 139)';
        } else if (Status === 'O') {
          cellElement.style.backgroundColor = 'rgba(0, 207, 232,0.12)';
          cellElement.style.color = 'rgb(29 74 74)';
        } else if (Status === 'P') {
          cellElement.style.backgroundColor = 'rgb(252 231 231)';
          cellElement.style.color = 'rgb(236 74 74)';
        } else if (Status === 'N') {
          cellElement.style.backgroundColor = 'rgba(247,247,247,1)';
          cellElement.style.color = 'rgba(33,33,33,1)';
        } else if (Status === 'C') {
          tr.classList.add('status-cancel');
        } else if (Status === 'S') {
          tr.classList.add('status-cancel');
        }
      } else {
        // 취소의 경우 한줄을 강조한다.
        if (Status === 'C' && tr) {
          tr.classList.add('status-cancel');
        }
      }
    }
  };
  const sampleData = [
    {
      MemberStatusName: '신규',
      VehicleRegistration: '경기나5777',
      MemberTypeName: '유료',
      StandardPrice: 5000,
      MemberDivisionName: '외부',
      MemberName: '조세호',
      GroupName: '테스트회사',
      CorpRegNumber: '2148618758',
      UseTag: '미사용',
      TagRentPrice: 0,
      MobliePhoneNo: '666666',
      TelephoneNo: '1111',
      CarType: '아반테',
      EmailAddress: 'test',
    },
    {
      MemberStatusName: '신규',
      VehicleRegistration: '서울바6858',
      MemberTypeName: '유료',
      StandardPrice: 0,
      MemberDivisionName: '외부',
      MemberName: '정신규',
      GroupName: '테스트회사',
      CorpRegNumber: '1248100998',
      UseTag: '미사용',
      TagRentPrice: 0,
      MobliePhoneNo: '16461',
      TelephoneNo: '010000',
      CarType: '벤츠',
      EmailAddress: 'test2',
    },
  ];

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const groupedData = new DataSource({
    store: autoDataSource,
    key: 'CostCenterCode',
  });
  return (
    <div>
      <div className={'content-block detail-title left-right-header'}>
        <div>
          <h2>정기권 엑셀 업로드</h2>
        </div>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={seasonticketUploadManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style={{ paddingTop: '10px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <h5>
              {' '}
              ★엑셀 파일 업로드 순서 : 샘플파일을 다운로드 &nbsp; ▷ &nbsp; 내용 추가 &nbsp; ▷ &nbsp;엑셀 파일 업로드
              &nbsp; ▷ &nbsp; 추가할 사업소명 검색 &nbsp; ▷ &nbsp; 저장
            </h5>
            <div style={{ flexGrow: 1 }}>&nbsp;</div>
            <ExcelImport
              fieldColumn={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']}
              handleImport={e => handleImport(e.map(importMapFunc))}
            />
          </div>
          <CommonDataGrid
            gridRef={dataGridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={700}
            onCellPrepared={onCellPrepared}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '정기권업로드.xlsx',
            }}
            headerBefore={[
              <Lookup
                labelMode="static"
                stylingMode="outlined"
                dataSource={groupedData}
                valueExpr="CostCenterName"
                displayExpr="CostCenterName"
                searchExpr="CostCenterName"
                searchEnabled={true}
                dropDownCentered={false}
                width={300}
                onValueChanged={onValueChanged}
                placeholder="P-코드/사업소명"
                onItemClick={onItemClick}
                mode="search"
              >
                <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
              </Lookup>,
            ]}
            headerAfter={[
              <Button
                icon="check"
                text="저장"
                width={80}
                type="normal"
                stylingMode="contained"
                onClick={() => saveExcel()}
              />,
              <ExcelExport
                excelFields={headerText}
                sheetName="Sheet"
                fileName="sample.xlsx"
                excelData={sampleData}
                buttonName="샘플파일 다운로드"
              />,
            ]}
            // headerAfter={[<Button icon="plus" text="매출 수기 등록" onClick={onOpen} />]}
            allowColumnReordering={true}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <LoadPanel enabled showPane={showPane} />
            <HeaderFilter visible={true} />
            <Column dataField={'MemberStatusName'} caption={'회원상태'} hidingPriority={4} />
            <Column dataField={'VehicleRegistration'} caption={'차량번호'} hidingPriority={4} />
            <Column dataField={'MemberTypeName'} caption={'정기권유형'} hidingPriority={4} />
            <Column dataField={'StandardPrice'} caption={'기준금액'} hidingPriority={4} />
            <Column dataField={'MemberDivisionName'} caption={'회원구분'} hidingPriority={4} />
            <Column dataField={'MemberName'} caption={'회원명'} hidingPriority={4} />
            <Column dataField={'GroupName'} caption={'회원그룹'} hidingPriority={4} />
            <Column dataField={'CorpRegNumber'} caption={'개인 사업자 번호'} hidingPriority={4} />
            <Column dataField={'UseTag'} caption={'태그사용 유무'} hidingPriority={4} />
            <Column dataField={'TagRentPrice'} caption={'태그비용'} hidingPriority={4} />
            <Column dataField={'MobliePhoneNo'} caption={'핸드폰번호'} hidingPriority={4} />
            <Column dataField={'TelephoneNo'} caption={'전화번호'} hidingPriority={4} />
            <Column dataField={'CarType'} caption={'차종'} hidingPriority={4} />
            <Column dataField={'EmailAddress'} caption={'이메일'} hidingPriority={4} />
            <Summary>
              {/* <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="ApprovalDate" /> */}
              <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default ExcelUploadSesonTicketMember;
