import { Column, Editing, Scrolling, Selection, Summary, TotalItem } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { NumberBox } from 'devextreme-react';

const CostGrid = forwardRef(({ title, height, dsCost }, ref) => {
  const gridRef = useRef({});

  const [dataSource, setDataSource] = useState([]);

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    getDataSource,
  }));

  const getDataSource = () => {
    return dataSource;
  };

  useEffect(() => {
    setDataSource(dsCost);
  }, [dsCost]);

  const onChangesChange = e => {
    console.log('onChangesChange...');
    if (e.length > 0) {
      gridRef.current.instance.saveEditData();
    }
  };

  /////////////////////////////////////////////////
  // const renderGridCellExpense = data => {
  //   console.log(data);
  //   return <div style={{ color: 'blue' }}>{data.text}</div>;
  // };

  const onValueChangedCell = (cell, e) => {
    console.log(cell);
    console.log(e);
    // cell.setValue(e.value);
  };

  const expenseEditorRender = cell => {
    // const onValueChanged = this.onValueChanged.bind(this, cell);
    return (
      <NumberBox
        defaultValue={cell.value}
        // value={cell.value}
        onValueChanged={onValueChangedCell}
        stylingMode={'outlined'}
      />
    );
  };
  return (
    <CommonDataGrid
      gridRef={gridRef}
      className={'dx-card'}
      dataSource={dataSource}
      showBorders={true}
      allowColumnResizing={true}
      columnHidingEnabled={false}
      height={height}
      // onCellPrepared={onCellPrepared}
      isHeader={true}
      isExcel={false}
      excelOptions={{}}
      allowColumnReordering={true}
      headerBefore={[<div className={'cost-title'}>{title}</div>]}
      headerAfter={[]}
      isNotFilterRow
    >
      <Editing
        mode="cell"
        allowAdding={false}
        allowDeleting={false}
        allowUpdating={true}
        onChangesChange={onChangesChange}
      />
      <Scrolling mode="virtual" rowRenderingMode="virtual" />
      <Selection mode="single" selectAllMode="pages" />
      {title !== '기타' && <Column dataField={'UseYN'} dataType="boolean" caption={'사용여부'} width={90} />}
      <Column dataField={'CostDetailName'} caption={'세부구분'} width={title !== '기타' ? 200 : 290} allowEditing={false} />
      <Column
        dataField={'Value'}
        width={100}
        caption={title === '고정경비' ? '금액(원)' : title === '기타' ? '값' : '금액(원)/입력값'}
        format="#,###.##"
        alignment="right"
        // format="#,##0 원"
        // cellRender={renderGridCellExpense}
        // editCellRender={expenseEditorRender}
      />
      <Column dataField={'Unit'} caption={'단위'} width={80} allowEditing={false} alignment="left" />
      {title === '기타' && <Column dataField={'ExtraField1'} caption={'조건금액'} width={100} />}
      <Column dataField={'참고용'} caption={'참고'} allowEditing={false} alignment="left" />
      {title === '고정경비' && (
        <Summary>
          <TotalItem column="Value" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      )}
    </CommonDataGrid>
  );
});

export default CostGrid;
