import axios from 'axios';
import { handleAuthError } from './error';
const userId = localStorage.getItem('userId');

/* 
  사업소 주차장 정보 관리 
*/

export async function getParkingInfo(params) {
  try {
    const result = await axios.post(`master/parkinginfo/searchParkingInfoByCostCenter`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingInfoSelectType(params) {
  try {
    const result = await axios.post(`master/codemaster/searchCodeMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function updateParkingInfo(params) {
  try {
    const result = await axios.post(`master/parkinginfo/updateParkingInfoByCostCenter`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingSytemInfo(params) {
  try {
    const result = await axios.post(`master/parkinginfo/searchParkingMgmtSystemInfoByCostCenter`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingSystemVendorMaster() {
  try {
    const result = await axios.post(`master/searchVendorMaster`, {
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingSystemVendorSW(params) {
  try {
    const result = await axios.post(`master/searchVendorSWVersionMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function updateParkingSystemInfo(params) {
  try {
    const result = await axios.post(`master/parkinginfo/updateParkingMgmtSystemInfoByCostCenter`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingSalsesAggregationInfo(params) {
  try {
    const result = await axios.post(`master/parkinginfo/searchSalesAggregationByCostCenterAgency`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingSalesCardAgencyType(params) {
  try {
    const result = await axios.post(`master/codemaster/searchCodeMaster `, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function deleteParkingSalesInfo(params) {
  try {
    const result = await axios.post(`master/parkinginfo/deleteSalesAggregation`, { ...params, LoginID: userId });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertSalesAggregation(params) {
  try {
    const result = await axios.post(`master/parkinginfo/insertSalesAggregation `, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertTerminalInfo(params) {
  try {
    const result = await axios.post(`master/parkinginfo/insertSalesAggregationTerminalInfo `, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function deleteTerminalInfo(params) {
  try {
    const result = await axios.post(`master/parkinginfo/deleteSalesAggregationTerminalInfoMulti`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getTerminalInfo(params) {
  try {
    const result = await axios.post(`master/parkinginfo/searchSalesAggregationTerminalInfo `, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function searchDistinctSalesAggregationByCostCenter(params) {
  try {
    const result = await axios.post(`master/parkinginfo/searchDistinctSalesAggregationByCostCenter `, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사업소 매출집계 정보관리2 > CMS계좌번호 저장
export async function insertCMSAccount(params) {
  try {
    const result = await axios.post(`master/parkinginfo/insertCMSAccount`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사업소 매출집계 정보관리2 > 제외 CMS 입금자명 저장
export async function insertCMSIgnoreSummary(params) {
  try {
    const result = await axios.post(`master/parkinginfo/insertCMSIgnoreSummary`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사업소 매출집계 정보관리2 > 목록 조회
export async function searchCMSAccountMaster(params) {
  try {
    const result = await axios.post(`master/parkinginfo/searchCMSAccountMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사업소 매출집계 정보관리2 > 사업소 CMS 계좌 목록 조회
export async function searchCMSAccountByCostCenter(params) {
  try {
    const result = await axios.post(`master/parkinginfo/searchCMSAccountByCostCenter`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사업소 매출집계 정보관리2 > 사업소 CMS 제외 계좌 목록 조회
export async function searchCMSIgnoreSummarytByCostCenter(params) {
  try {
    const result = await axios.post(`master/parkinginfo/searchCMSIgnoreSummarytByCostCenter`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 사업소 매출집계 정보관리2 > 단말기 정보
export async function searchSalesAggregationByCostCenter(params) {
  try {
    const result = await axios.post(`master/parkinginfo/searchSalesAggregationByCostCenter`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사업소 매출집계 정보관리2 > 사업소 CMS 계좌번호 삭제
export async function deleteCMSAccount(params) {
  try {
    const result = await axios.post(`master/parkinginfo/deleteCMSAccount`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사업소 매출집계 정보관리2 > 사업소 제외 CMS 입금자명 삭제
export async function deleteCMSIgnoreSummary(params) {
  try {
    const result = await axios.post(`master/parkinginfo/deleteCMSIgnoreSummary`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

//사업소 별, 월별 비용 조회 기능
export async function getParkingCostManagementData(params) {
  try {
    const result = await axios.post(`aggregation/getParkingCostManagementData`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

//사업소 별, 월별 비용 저장 기능
export async function saveParkingCostManagementData(params) {
  try {
    const result = await axios.post(`aggregation/saveParkingCostManagementData`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
